import React, { useState } from "react";
import {
  IonContent,
  IonIcon,
  IonToolbar,
  IonInput,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { heart, hourglassOutline } from "ionicons/icons";
import LabelMatched from "../../components/LabelMatched/LabelMatched";
import "./InviteMentorModal.scss";

import { getVersion, inviteMentor, validateEmail } from '../../apis/courtli/courtli';

type Props = {
  user: any,
  onClose: () => void
};

const InviteMentorModal: React.FC<Props> = ({ user, onClose }) => {
  

  /// VARS
  let [email, setEmail] = useState("");

  /// FUNCTIONS
  async function inviteAFriend(){
    console.log("inviteAFriend() email", email);

    if(validateEmail(email)){
      let res = await inviteMentor(email);
      onClose();
    }else{
      alert("Please provide a valid email!");
    }
  }
  return (
    <IonContent force-overscroll="false" className="matched-modal-page">
      <div className="matched-container">
       
        <div className="mentor-overlay background_gradient">
          <div className="margin_auto">
            <div className="megaemoji margin_auto">🙏</div>
            <div className="margin_auto margin_top_5px t1 white text_align_center">Keep calm</div>
            <div className="margin_auto white text_align_center t3">
                The best mentor is actually someone you know.                
            </div>
            
            <div className="margin_auto margin_top_40px color_dark_gray text_align_center width_60p">
              <IonInput
                    className="cinput"
                    placeholder="Friends email"
                    onIonChange={ (e:any) => { 
                        // console.log("PhoneWizard Code onChange() e.target.value", e.target.value);
                        setEmail(e.target.value);
                    }}
                />
            </div>
            <div className="t5 margin_auto white text_align_center">
              <br/>Invite a friend to be your mentor now!
              <br/>Your mentor can suggest matches to you.
              <br/>And they can also help you improve your profile.
            </div>
          </div>

          <div className="display_block margin_top_80px">
          <div className="matched-input">
            <div className="label">              
              <div>
              </div>
            </div>
          </div>
          
            <IonButton shape="round" color="light" className="width_60p margin_auto display_block margin_top_20px" onClick={inviteAFriend}>
              Invite
            </IonButton>
            <IonButton fill="clear" color="light" className="display_block" onClick={onClose}>
              Back to Courtli
            </IonButton>
            {/* <IonButton fill="clear" color="primary" className="display_block" onClick={onClose}>
              Get a mentor to help you!
            </IonButton> */}
          </div>
        </div>
      </div>
    </IonContent>
  );
};

InviteMentorModal.defaultProps = {};

export default InviteMentorModal;
