// @ts-nocheck
import React, { Component, useEffect, useState, useRef } from 'react';
import {
    IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
    IonItem,
    IonLabel,
    IonSpinner,
    IonBackButton,
    IonInput,
    IonButtons,

    //LIST
    IonList, IonSelect, IonSelectOption,

    //ICONS
    IonIcon

} from '@ionic/react';

import {
    courtli,
    courtliHealthCheckWithRedirect,
    getOnboardingNextStep,
    initOnboarding,
    changeUserField,
    changeUserFieldAlsoExtraData,
    goBack,
    getUser,
    getAge,
    goToUrlAndReload 
} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack, chevronForward } from 'ionicons/icons';
import './Wizards.scss';
import { last } from 'rxjs';

// import './Facebook.css';


/// CONSTANTS


type Props = {
    history: any;
    user: any;
    onClose: () => void;
};

const NameWizard: React.FC<Props> = ({ history, user, onClose }) => {


    /// VARIABLES
    let [name, setName] = useState("");
    let [lastName, setLastName] = useState("");
    let [email, setEmail] = useState("");
    let [hand, setHand] = useState("");
    let [gender, setGender] = useState("");
    let [genderInterested, setGenderInterested] = useState("");

    // let [nextStep, setNextStep] = useState<any>({
    //     title: "START NOW",
    //     wizard: "/tabs/profile"
    // });
    /// FUNCTION DEFINITIONS


    /// EXECUTION
    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        let user = await getUser({ use_cache: false });
        console.log("NameWizard fetchData() user", user);
        setName(user.first_name);
        setLastName(user.last_name);
        courtliHealthCheckWithRedirect(history);
        // initOnboarding(setNextStep);
    }


    //BIRTHDAY
    let [birthday, setBirthday] = useState(946723843000);
    let [age, setAge] = useState(0);
    function calculateAgeAndSetBirthday(birthday_date: string) {
        if (!birthday_date || birthday_date.length === 0) {
            return;
        }
        let new_birthday = new Date(birthday_date);
        setBirthday(new_birthday.getTime());

        let new_age = getAge(new_birthday.toString());
        setAge(new_age);
    }


    //HAND
    const [selectedHand, setSelectedHand] = useState("SELECT HAND YOU USE WHILE USING APP");
    const handElementRef = useRef<HTMLInputElement>(null);
    async function openSelectHand() {
        if (handElementRef.current != null) {
            handElementRef.current.click();
        }
    }

    //GENDER
    const [selectedGender, setSelectedGender] = useState("SELECT GENDER");
    const genderElementRef = useRef<HTMLInputElement>(null);
    async function openSelectGender() {
        if (genderElementRef.current != null) {
            genderElementRef.current.click();
        }
    }

    //GENDER INTERESTED
    const [selectedGenderInterested, setSelectedGenderInterested] = useState("SELECT GENDER");
    const genderInterestedElementRef = useRef<HTMLInputElement>(null);
    async function openSelectGenderInterested() {
        if (genderInterestedElementRef.current != null) {
            genderInterestedElementRef.current.click();
        }
    }

    async function saveName() {

        if (name.length > 1 && lastName.length > 1) {
            let result = await changeUserField("first_name", name);
            let result2 = await changeUserField("last_name", lastName);
            if (result.response_code === 1) {
                goBack(history, { refresh: true });
            } else {
                alert("We could not save your name - make sure there are only letters in it.");
            }
        } else {
            alert("Your name must be at least 2 characters long");
        }

    }

    async function saveAllFields(){
        if (name.length < 1){
            alert("Please enter your first name");
            return;
        }else if (lastName.length < 1){
            alert("Please enter your last name");
            return;
        }else if(email.length < 1){
            alert("Please enter your email");
            return;
        }else if(birthday.length < 1){
            alert("Please enter your birthday");
            return;
        }else if(selectedHand !== "Right" && selectedHand != "Left"){
            alert("Please select your handedness");
            return;
        }else if(selectedGender !== "Male" && selectedGender != "Female"){
            alert("Please select your gender");
            return;
        }else if(selectedGenderInterested !== "Male" && selectedGenderInterested != "Female"){
            alert("Please select the gender you are interested in");
            return;
        }

        /// SAVE ALL FIELDS TO DB
        let result = await changeUserField("first_name", name);
        let result2 = await changeUserField("last_name", lastName);
        let result3 = await changeUserField("email", email);
        let result4 = await changeUserField("birthday_at", birthday);

        let is_right_handed = 1;
        if(selectedHand === "Left"){
            is_right_handed = 0;
        }
        let result5 = await changeUserField("is_right_handed", is_right_handed);

        let gender = selectedGender == "Male" ? "man" : selectedGender;
        gender = gender == "Female" ? "woman" : gender;
        let result6 = await changeUserFieldAlsoExtraData("extra_data.my_gender", gender);
        let gender2 = selectedGenderInterested == "Male" ? "man" : selectedGenderInterested;
        gender2 = gender2 == "Female" ? "woman" : gender2;
        let result7 = await changeUserFieldAlsoExtraData("extra_data.looking_for", gender2);
        
        /// CONSOLE LOG ALL RESULTS
        console.log("saveAllFields() results", [
            result,
            result2,
            result3,
            result4,
            result5,
            result6,
            result7
        ]);

        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
        let user = await getUser({use_cache: false});
        console.log("saveAllFields() user", user);
        let next_step = getOnboardingNextStep(user);
        console.log("saveAllFields() next_step", next_step);
        history.push(next_step.wizard);

        /// NO NEED TO RELOAD
        // goToUrlAndReload(history, next_step.wizard);
        
    }



    return (
        <IonPage className="wizard-page">

            <IonHeader className="no-border">
                <IonToolbar className="user-profile-toolbar toolbar-no-border ">
                    <IonButtons slot="start">
                        <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
                    </IonButtons>
                    {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
                    <IonButtons slot="end">
                        <IonButton></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>


            <IonContent className="ion-padding" fullscreen={true}>
                <IonRow>
                    <div className="top-emoji">✌️</div>
                </IonRow>
                <IonRow>
                    <div className="top-title">
                        General
                    </div>
                </IonRow>

                <IonRow>
                    <div className="inputs-container ">

                        <IonInput
                            class="cinput"
                            placeholder="ENTER YOUR FIRST NAME"
                            value={name}
                            onIonChange={(e: any) => {
                                setName(e.target.value.toUpperCase());
                            }}
                        >
                            <div className="clabel">
                                FIRST NAME
                            </div>
                        </IonInput>

                        <IonInput
                            class="cinput"
                            placeholder="ENTER YOUR LAST NAME"
                            value={lastName}
                            onIonChange={(e: any) => {
                                setLastName(e.target.value.toUpperCase());
                            }}
                        >
                            <div className="clabel">
                                LAST NAME
                            </div>
                        </IonInput>



                        <div className={"select-button"}>
                            <div className="title">BIRTHDAY</div>
                            <input
                                type="date"
                                className="cdate_input"
                                value={new Date(birthday).toISOString().slice(0, 10)}
                                onInput={(e: any) => calculateAgeAndSetBirthday(e.target.value)}
                            />
                        </div>


                        <IonInput
                            class="cinput"
                            placeholder="ENTER YOUR EMAIL"
                            value={email}
                            onIonChange={(e: any) => {
                                setEmail(e.target.value.toUpperCase());
                            }}
                        >
                            <div className="clabel">
                                YOUR E-MAIL
                            </div>
                        </IonInput>


                        <div className={"select-button"} onClick={() => (openSelectHand())}>
                            <div className="title">HANDENESS</div>
                            <div className="description">{selectedHand.toUpperCase()}</div>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                        </div>

                        <IonList className='display_none'>
                            <IonItem >
                                <IonSelect placeholder="" ref={handElementRef} onIonChange={(e) => setSelectedHand(e.detail.value)}>
                                    <ion-label>Select</ion-label>
                                    <IonSelectOption value="Left">Left</IonSelectOption>
                                    <IonSelectOption value="Right">Right</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>


                        <div className={"select-button"} onClick={() => (openSelectGender())}>
                            <div className="title">GENDER</div>
                            <div className="description">{selectedGender.toUpperCase()}</div>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                        </div>

                        <IonList className='display_none'>
                            <IonItem >
                                <IonSelect placeholder="" ref={genderElementRef} onIonChange={(e) => setSelectedGender(e.detail.value)}>
                                    <ion-label>Select</ion-label>
                                    <IonSelectOption value="Male">Male</IonSelectOption>
                                    <IonSelectOption value="Female">Female</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>


                        <div className={"select-button"} onClick={() => (openSelectGenderInterested())}>
                            <div className="title">GENDER INTERESTED</div>
                            <div className="description">{selectedGenderInterested.toUpperCase()}</div>
                            <IonIcon slot="icon-only" icon={chevronForward} />
                        </div>

                        <IonList className='display_none'>
                            <IonItem >
                                <IonSelect placeholder="" ref={genderInterestedElementRef} onIonChange={(e) => setSelectedGenderInterested(e.detail.value)}>
                                    <ion-label>Select</ion-label>
                                    <IonSelectOption value="Male">Male</IonSelectOption>
                                    <IonSelectOption value="Female">Female</IonSelectOption>
                                </IonSelect>
                            </IonItem>
                        </IonList>


                    </div>
                </IonRow>


                <IonRow class="wizard_action_row">
                    <div className="flex">
                        <div className="margin_auto  cbutton_red pointer" onClick={() => (saveAllFields())}>SAVE</div>
                    </div>
                </IonRow>

            </IonContent>
        </IonPage >
    )
}

NameWizard.defaultProps = {};

export default NameWizard;