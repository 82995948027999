import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonLoading,
  IonRow,
  IonRouterLink,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons
} from "@ionic/react";
import { logoApple, logoFacebook, logoGoogle, chatbubble } from "ionicons/icons";
import "./Login.scss";
import { motion } from "framer-motion";
import {
  courtli,
  saveToLocalStorage, getVersion, setToken, getToken, getBrowser,
  refreshToLatestVersion
} from '../../apis/courtli/courtli';
import HeartsAnimation from '../../components/HeartsAnimation/HeartsAnimation';

type Props = {
  history: any;
};

const Login: React.FC<Props> = ({ history }) => {


  const [pwaModalIsOpen, setPwaModalIsOpen] = useState(false);
  const [pwaModalContent, setPwaModalContent] = useState(<></>);

  const version = getVersion() + " " + getBrowser();
  let params = useParams<{ token: string }>();

  const goToFacebookLogin = () => {
    history.push("/login/facebook");
  };
  const goToGoogleLogin = () => {
    history.push("/login/google");
  };
  const goToSMSVerification = () => {
    history.push("/wizards/phone");
  };



  function closePwaModal() {
    courtli.pwa.modal_closed = true;
    saveToLocalStorage();
    setPwaModalIsOpen(false);
  }

  function startPwa() {

    let browser = getBrowser();
    // console.log("startPwa() browser", browser);
    // console.log("startPwa() courtli", courtli);
    if (browser === "Safari") {
      /// Popup that explains how to add to home screen
      console.log("Prompting mobile Safari PWA");
      let share_img = "https://wallpapers.iosnewsandmore.tk/share.png";///"/assets/images/safari_share_icon.png"
      let content = <>
        <div className="gs_card t4 display_block margin_auto">
          <div>
            {/* <div className="gs_info_icon"><span>i</span></div> */}
            {/* <div className="t2 text_center center_text_in_div margin_bottom_10px">
              Welcome!
            </div> */}
            <div className="t3 text_center">
              Most users prefer our app
            </div>
            <div className="t4 text_align_left width_80p margin_auto">
              <br />
              <br />1. Click <span><img src={share_img} className="height_16px" /></span> at the bottom
              <span><br />2. Scroll down and click "Add to Home Screen"
                <br />3. Launch the app from your Home Screen!</span>
            </div>
          </div>
        </div>

        <IonRow class="margin_top_40px">
          <IonRouterLink class='margin_auto t4 red' onClick={() => { closePwaModal() }}>Skip this</IonRouterLink>
        </IonRow>
      </>;
      setPwaModalContent(content);

      let pwa_mode = false;
      if (window.matchMedia('(display-mode: standalone)').matches) {
        // PWA mode
        pwa_mode = true;
      }

      let fullscreen = document.fullscreenElement;
      console.log("Login fullscreen", fullscreen);
      let show_popup = !pwa_mode; ///!courtli.pwa.modal_closed; ///!fullscreen;
      if (show_popup) {
        setPwaModalIsOpen(true);
      }
    }


  }
  useEffect(() => {

    startPwa();

    // MANUALLY SETTING A TOKEN
    //params.token = "u8lYGBCtVzm9XcEMm8DpadIIPTRizz";

    if (params.token) {
      setTokenAndReload(params.token);
    }

    async function setTokenAndReload(token: string) {
      await setToken({ token: token });
      await getToken();
      history.push("/wizards/setup");
    }
  }, []);

  return (

    <IonPage>
      <IonContent forceOverscroll={false} className="login-page bg-gradient">

        <div className="vertical-layout safe-area-bottom">
          <div className="section-brand z101">
            <motion.div className="logo" animate={{ scale: [1, 1.5, 1] }} transition={{ duration: 1 }}>
              <img
                className="logo-icon"
                src="assets/img/courtli_logo_vertical.svg"
                alt=""
              />
            </motion.div>
          </div>

          <div className="section-buttons ion-padding">

            {/* <IonButton
              fill="clear"
              color="white"
              className="button-clear text-underline"
            >
              Create a new account
            </IonButton> */}

            <div className="social-btns-holder z101">
              {/* <IonButton
                expand="block"
                fill="outline"
                color="white"
                shape="round"
                className="button-facebook button-login"
                onClick={goToFirstPage}
              >
                <IonIcon slot="start" icon={logoApple} size="small" />
                SIGN IN WITH APPLE
              </IonButton> */}


              <IonButton
                expand="block"
                fill="outline"
                color="white"
                shape="round"
                className="button-facebook button-login"
                onClick={goToGoogleLogin}
              >
                <IonIcon slot="start" icon={logoGoogle} size="small" />
                Sign in with Google
              </IonButton>

              <IonButton
                expand="block"
                fill="outline"
                color="white"
                shape="round"
                className="button-login ion-margin-bottom"
                onClick={goToSMSVerification}
              >
                <IonIcon slot="start" icon={chatbubble} size="small" />
                Sign in via SMS
              </IonButton>

              <IonButton
                expand="block"
                fill="outline"
                color="white"
                shape="round"
                className="cbutton button-facebook button-login t3 pointer"
                onClick={goToFacebookLogin}
              >
                <IonIcon slot="start" icon={logoFacebook} size="medium" />
                Sign in with Facebook
              </IonButton>
            </div>

            <div className="ion-padding terms_text light t4 z101">
              By tapping "Sign in", you agree to our{" "}
              <a className="bold inline pointer white" target="_blank" href="/terms-and-conditions">Terms</a>.
              Please read our{" "}
              <a className="bold inline pointer white" target="_blank" href="/privacy-policy">Privacy Policy</a>.
            </div>

            {/* <div className="bold t4 padding_bottom_20px pointer z101">
              Trouble logging in?
            </div> */}
            {/* <IonIcon slot="start" icon={logoFacebook} size="large" />
            <IonIcon slot="start" icon={logoGoogle} size="large" />
            <IonIcon slot="start" icon={logoApple} size="large" /> */}

            <div className="t5 z101">
              {version} <div onClick={() => { refreshToLatestVersion(history) }} className="pointer display_inline_block">🔨</div>
            </div>
          </div>
          <HeartsAnimation mode="landing_page" />
        </div>

        {/* PWA EXPLAINED MODAL */}
        <IonModal isOpen={pwaModalIsOpen}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Add to Home Screen</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setPwaModalIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding bg-gradient">
            <div className="gs_card">
              <img src="/assets/img/logo.jpeg" className="margin_auto display_block margin_bottom_20px"></img>

              {pwaModalContent}

            </div>
          </IonContent>
        </IonModal>

      </IonContent>
    </IonPage>
  );
};

Login.defaultProps = {};

export default Login;
