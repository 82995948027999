// @ts-nocheck

import React from "react";

import {
  IonPage, IonContent, IonRow, IonGrid, IonCol, IonButton, IonIcon, IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonAvatar, IonBackButton,
  IonSearchbar,
  IonList,
  IonListHeader,
  IonItem,
  IonNote,
  IonRange,
  IonSelect,
  IonSelectOption,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonText
} from '@ionic/react';
import {
  shareOutline, chatbubble, filterSharp
} from 'ionicons/icons';
import * as swing from 'swing';
import ReactSwing from 'react-swing';
import './Discover.scss';
import RippleLoader from '../../components/RippleLoader/RippleLoader';
import SwipeCard from '../../components/SwipeCard/SwipeCard';
import MatchedModal from '../MatchedModal/MatchedModal';
import MentorModal from '../MentorModal/MentorModal';
import InviteMentorModal from '../InviteMentorModal/InviteMentorModal';
import MenteeShareModal from '../MenteeShareModal/MenteeShareModal';

//PROFILE
import Profile from '../Profile/Profile';
import MentorProfile from '../MentorProfile/MentorProfile';

//ANIMATION
import { enterAnimation, leaveAnimation, } from '../../animations/animations2';

// import USERS from './users.dummy';
import { RouteComponentProps } from 'react-router';

// COURTLI
import { courtliHealthCheckWithRedirect, addMatch, getDiscoverCards, getUser, getSharedId, removeSharedId, generateShareUrl, getSharedUrl, getUserById, canShowMentorModal } from '../../apis/courtli/courtli';

//SHARE
import { Share } from '@capacitor/share';

//UTILS
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';


interface OwnProps extends RouteComponentProps {
  OwnProps: any;
}

type Props = {
  cards: User[],
  history: any,
  user: any,
  other_user: any,
  profile_image_url: any
}

type User = {
  id: number,
  name: string,
  age: number,
  job_title: string,
  profile_image_url: string,
  mentee_profile_public: number,
  images: any[],
  current_role: string
}
interface SearchQuery {
  [key: string]: any;
}

type State = {
  isLoading: boolean,
  searchModalClassName: string,
  searchQuery: SearchQuery,
  isLocked: boolean,
  isMatchModalOpen: boolean,
  isMentorModalOpen: boolean,
  isInviteMentorModalOpen: boolean,
  isMenteeShareModalOpen: boolean,
  isProfileOpen: boolean,
  currentProfile: User | any,
  current_role: string,
  cards: any,
  prevCard: any,
  stack: swing.Stack | null,
  user: any,
  mode: string
}



class Discover extends React.Component<Props, State> {


  state = {
    isLoading: false,
    searchQuery: {
      role: "mentee"
    },
    searchModalClassName: "display_none",
    isLocked: false,
    isMatchModalOpen: false,
    isMentorModalOpen: false,
    isInviteMentorModalOpen: false,
    isMenteeShareModalOpen: false,
    isProfileOpen: false,
    currentProfile: null,
    current_role: "mentee",
    // CARDS ARE PASSED FROM TABS
    cards: [],
    prevCard: null,
    user: {
      profile_image_url: this.props.profile_image_url,
      mentee_profile_public: 0
    },
    stack: null,
    other_user: false,
    mode: "MATCHES"
  }

  public constructor(props: Props) {

    super(props);

    this.stackRef = React.createRef();
    this.nopeEl = null;
    this.likeEl = null;
    this.nextCardEl = null;


  }

  goToProfile = () => {
    this.props.history.push('/tabs/profile');
  }
  goToMatches = () => {
    this.props.history.push('/matches');
  }
  goToChatWithUser = (user_id: string) => {
    this.handleToggleMatchModal();
    this.props.history.push('/chat/' + user_id);
  }
  findAMentor = () => {
    this.handleToggleMentorModal();
    let sq = this.state.searchQuery;
    sq.role = "mentor";
    this.setState({ searchQuery: sq });
    this.getData();
  }


  componentDidMount() {
    this.getData();
  }

  fetchData = async () => {

    /// DO WE REALLY NEED TO FETCH USER ON EVERY REFRESH?
    let user = await getUser({ use_cache: false });
    // console.log("Discover getData() user", user);
    this.setState({
      isLoading: true,
      user: user,
      cards: []
    });

    let cards = [];

    /// NOW SEARCH WITH SEARCH QUERY
    //console.log("Discover searchQuery", this.state.searchQuery);

    cards = await getDiscoverCards({
      role: this.state.searchQuery.role,
      search: this.state.searchQuery
    });
    //console.log("Discover  cards", cards);


    //SHARE USER
    getSharedId().then((sharedId) => {

      if (sharedId) {
        getUserById(sharedId).then((sharedUser) => {
          cards.push(sharedUser);
          this.setState({ cards: cards });
          removeSharedId();
        });
      }

    });


    if (cards.length === 0) {
      /// WHAT TO DO IF CARDS ARE EMPTY?
      this.handleItWhenCardsRunOut();
    } else {
      this.setState({
        isLoading: false,
        user: user,
        cards: cards
      });
    }

  }

  getData = () => {
    courtliHealthCheckWithRedirect(this.props.history);
    this.setState({ isLoading: true });
    this.fetchData();
  }

  getTopCardEl = () => {
    if (this.stackRef) {
      // @ts-ignore
      const { children } = this.stackRef.current;
      let targetEl;

      if (children.length >= 1) {
        targetEl = children[children.length - 1];
      }

      if (targetEl) return targetEl;
    }

    return null;
  }

  getNextCardEl = () => {
    if (this.stackRef) {
      // @ts-ignore
      const { children } = this.stackRef.current;
      let targetEl;

      if (children.length >= 2) {
        targetEl = children[children.length - 2];
      }

      if (targetEl) return targetEl;
    }

    return null;
  }

  checkMatching = (card: any) => {
    this.addMatchAndCheckViceVersa(card);
  }

  addMatchAndCheckViceVersa = async (card: any) => {

    console.log("addMatchAndCheckViceVersa() card", card);
    let result = await addMatch(card.id);
    console.log("addMatchAndCheckViceVersa() result", result);
    if (!result) {
      console.log("addMatchAndCheckViceVersa() failed to get match data");
      return;
    }

    if (result.match_vice_versa) {
      console.info('MATCHED!!!');
      this.state.other_user = card;
      this.handleToggleMatchModal();
    }

  }

  //UNDO CARD
  undoCard = () => {
    if (this.state.prevCard) {
      let cards = this.state.cards;
      cards.push(this.state.prevCard);
      this.setState({ cards: cards, prevCard: null });
    }
  }

  handleSetStack = (swingStack: any) => {
    this.setState({ stack: swingStack });

    const topCardEl = this.getTopCardEl();

    if (topCardEl) {
      // this.nopeEl = topCardEl.querySelector('.stamp-nope');
      // this.likeEl = topCardEl.querySelector('.stamp-like');
    }

    this.nextCardEl = this.getNextCardEl();
  }

  handleButtonClicked = (type: string = 'NOPE') => {
    if (this.state.isLocked) return false;

    const { stack } = this.state;

    if (this.stackRef && stack) {
      const topCardEl = this.getTopCardEl();

      if (topCardEl) {
        // if (type === 'NOPE') {
        //   if (this.nopeEl) this.nopeEl.style.opacity = '1';
        // } else {
        //   if (this.likeEl) this.likeEl.style.opacity = '1';
        // }

        setTimeout(() => {
          // @ts-ignore
          const card = stack.getCard(topCardEl);
          const throwX = type === 'NOPE' ? -0.5 * topCardEl.offsetWidth : 0.5 * topCardEl.offsetWidth;
          card.throwOut(throwX, 20, type === 'NOPE' ? ReactSwing.DIRECTION.LEFT : ReactSwing.DIRECTION.RIGHT);
        }, 500);
      }
    }
  }

  handleClickNope = () => {
    this.handleButtonClicked('NOPE');
    return true;
  }

  handleClickLike = () => {
    this.handleButtonClicked('LIKE');
    return true;
  }

  handleGetMoreCards = () => {
    if (this.state.isLocked) return false;

    this.getData();
  }

  // Called whenever we drag an element
  handleCardDragging = (element: HTMLElement, x: number, y: number, r: number) => {
    const calculatedValue = Math.min(100, Math.abs(x) - 20) / 100;// 0 <-> 1 for Opacity

    window.requestAnimationFrame(() => {
      element.style.transform = `translate3d(${x}px, ${y}px, 0) rotate(${r}deg)`;
    });

    if (Math.abs(x) > 20 && Math.abs(x) <= element.offsetWidth / 2) {
      // window.requestAnimationFrame(() => {
      //   // @ts-ignore
      //   this.nopeEl.style.opacity = x < 0 ? calculatedValue : 0;
      //   // @ts-ignore
      //   this.likeEl.style.opacity = x < 0 ? 0 : calculatedValue;
      // })

      // Zoom effect for the card behind the current one
      window.requestAnimationFrame(() => {
        if (this.nextCardEl) {
          this.nextCardEl.style.transform = `translate3d(0,0,0) scale(${0.94 + 0.06 * calculatedValue}, ${0.94 + 0.06 * calculatedValue})`;
        }
      });
    } else if (x === 0) {
      // window.requestAnimationFrame(() => {
      //   // @ts-ignore
      //   this.likeEl.style.opacity = 0;
      //   // @ts-ignore
      //   this.nopeEl.style.opacity = 0;
      // })
    }
  }

  handleCardThrowOut = (e: any, direction = ReactSwing.DIRECTION.LEFT) => {
    this.setState({ isLocked: true });

    const removedCard: User = this.state.cards[this.state.cards.length - 1];

    if (direction === ReactSwing.DIRECTION.RIGHT) {
      this.checkMatching(removedCard);
    }

    // console.log(`${direction === ReactSwing.DIRECTION.LEFT ? 'SWIPED LEFT' : 'SWIPED RIGHT'}: ${ removedCard.name }`);

    //UNDO CARD
    this.state.prevCard = removedCard;
  }

  handleThrowIn = () => {
    this.setState({ isLocked: false });
  }

  handleCardThrowOutEnd = () => {


    let { cards: cardList } = this.state;

    // Remove the last element
    cardList.pop();

    this.setState({
      cards: cardList
    }, () => {
      // Scale the top card to its full size
      const topCardEl = this.getTopCardEl();

      if (topCardEl) {
        topCardEl.style.transform = 'scale(1, 1)';
      }

      this.setState({ isLocked: false });

      // If there's no more cards left, show Loading screen and load more run out
      // console.log("handleCardThrowOutEnd() this.state.cards", this.state.cards);
      if (this.state.cards.length === 0) {
        this.handleItWhenCardsRunOut();
        this.setState({ isLoading: true });


        // console.log("handleCardThrowOutEnd() NO MORE CARDS calling getData");

        /// DONT CALL NEW DATA BECAUSE IT WILL BE DONE BY THE MODAL
        /// CORRECTION: ITS ONLY SOMETIMES DONE BY THE MODAL IF THEY DONT CLICK SKIP/BACK - SAFER TO CALL ALWAYS

        //ANDRES - I DISABLE IT SO THE USER CAN CHANGE THE FILTERS
        //this.getData();
      }
    });
  }

  handleItWhenCardsRunOut = () => {
    if (this.state.mode === "MATCHES") {

      canShowMentorModal().then((showModal) => {
        if (showModal) {
          this.handleToggleMentorModal();
        }
      });

    } else {
      this.handleToggleInviteMentorModal();
    }
  }
  handleToggleMatchModal = () => {
    this.setState({
      isMatchModalOpen: !this.state.isMatchModalOpen,
    })
  }
  handleToggleMentorModal = () => {
    this.setState({
      isMentorModalOpen: !this.state.isMentorModalOpen,
    })
  }
  handleToggleInviteMentorModal = () => {
    this.setState({
      isInviteMentorModalOpen: !this.state.isInviteMentorModalOpen,
    })
  }
  handleToggleMenteeShareModal = () => {
    if (this.state.isMenteeShareModalOpen) {
      this.setState({
        isMenteeShareModalOpen: false,
      })
    }
  }

  handleNoMoreSlide = (isOnTheLeft: boolean) => {
    if (this.stackRef && this.stackRef.current) {
      const className = isOnTheLeft ? 'rotate-left' : 'rotate-right';

      // @ts-ignore
      this.stackRef.current.classList.add(className);
      setTimeout(async () => {
        // @ts-ignore
        this.stackRef.current.classList.remove(className);

        let cards = await getDiscoverCards({
          role: this.state.searchQuery.role
        });
        this.state.cards = cards;
      }, 250);
    }
  }
  handleOpenProfile = (user: User) => {
    this.setState({
      isProfileOpen: !this.state.isProfileOpen,
      currentProfile: user,
      current_role: user.current_role
    })
  }

  handleToggleProfile = () => {
    this.setState({
      isProfileOpen: !this.state.isProfileOpen,
    })
  }


  /// SEARCH
  toggleSearchModal(state: boolean) {
    if (state) {
      this.setState({
        searchModalClassName: "display_flex"
      });
    } else {
      this.setState({
        searchModalClassName: "display_none"
      });
    }
  }


  setRangeValue(label: string, detail: any) {
    // console.log("setRangeValue()", {label: label, detail: detail});
    let sq = this.state.searchQuery;
    let value = detail.value;
    if (label === "rating") {
      value.upper = value.upper / 10;
      value.lower = value.lower / 10;
    }

    sq[label] = value;
    this.setState({ searchQuery: sq });

    // console.log("searchQuery", searchQuery);
  }


  /// MENTOR FUNCTIONS
  chatWithMentee = () => {
    let currentUser = this.getCurrentUser();
    // console.log("Discover chatWithMentee() currentUser", currentUser);
    this.props.history.push("/chat/" + currentUser.id);
  }

  //SHARE USER
  shareMenteeProfile = () => {

    let currentUser = this.getCurrentUser();

    generateShareUrl(currentUser.id).then((res) => {

      let shareUrl = res;
      let _ = Share.share({
        text: "What do you think about this profile?\n" + shareUrl,
      }).catch(error => {

        //Open share via email
        console.log(error);
        this.setState({
          other_user: currentUser
        });
        this.state.isMenteeShareModalOpen = true;
      });
    }).catch((error) => {
      console.log(error);
    });

  }

  getCurrentUser = () => {
    const removedCard: User = this.state.cards[this.state.cards.length - 1];
    return removedCard;
  }
  /**
    Read more: https://github.com/gajus/swing#configuration
  */
  stackConfig = {
    // Default setting only allows UP, LEFT and RIGHT so you can override this as below
    allowedDirections: [
      ReactSwing.DIRECTION.LEFT,
      ReactSwing.DIRECTION.RIGHT
    ],
    throwOutConfidence: (offsetX: number, _offsetY: number, element: HTMLElement) => {
      return Math.min(Math.abs(offsetX) / (element.offsetWidth / 2), 1);
    },
    transform: (element: HTMLElement, x: number, y: number, r: number) => {
      this.handleCardDragging(element, x, y, r);
    },
    throwOutDistance: () => {
      return window.outerWidth * 1.5;
    }
  };



  stackRef: React.RefObject<HTMLDivElement>;
  nopeEl: HTMLElement | null;
  likeEl: HTMLElement | null;
  nextCardEl: HTMLElement | null;


  // this.setState({
  //   isLoading: false,
  //   cards: this.props.cards
  //   // cards2: [...USERS],
  // });

  render() {

    const {
      user,
      cards,
      isLoading,
      isMatchModalOpen,
      isMentorModalOpen,
      isInviteMentorModalOpen,
      isMenteeShareModalOpen,
      isProfileOpen,
      currentProfile,
      current_role,
      searchModalClassName,
      searchQuery
    } = this.state;


    // let props:any = this.props;

    // let user:any = {};    
    // if(props && props.user){
    //   user = props.user;      
    // }
    // console.log("Discover render() user", user);

    // let cards = [];
    // if(props && props.cards){
    //   cards = props.cards;      
    // }else{
    //   cards = this.state.cards;
    // }
    // console.log("Explore render() cards", cards);

    // const { cards } = this.props.cards;

    return (
      <IonPage className="discover-page">
        <IonHeader className='no-border'>
          <IonToolbar className='discover-toolbar toolbar-no-border toolbar-no-safe-area'>

            <IonButtons slot='start'>
              <IonAvatar onClick={this.goToProfile}>
                <img src={user.profile_image_url} alt="" />
              </IonAvatar>
            </IonButtons>

            <IonButtons slot="end">
              <IonButton>
                <div className='filter-button' onClick={() => { this.toggleSearchModal(true); }}>
                  <IonIcon icon={filterSharp} />
                </div>
              </IonButton>
            </IonButtons>

          </IonToolbar>
        </IonHeader>
        <IonContent className="discover-bg" forceOverscroll={false}>
          {
            isLoading &&
            <div className="full-height safe-area-bottom">
              <IonRow className="full-height ion-justify-content-center ion-align-items-center">
                <RippleLoader imageUrl={user.profile_image_url} mode={this.state.mode} />

                <h5 className="card-info cards_ran_out_text">
                  We can't find more matches for you. You can edit your <a onClick={() => { this.goToProfile() }}>Profile</a> or search a Mentee for help.
                </h5>

              </IonRow>
            </div>
          }
          {
            !isLoading && !user.mentee_profile_public &&
            <div className="cards-container safe-area-bottom">
              <div className="margin_auto text_align_center">
                <div className="megaemoji margin_auto">🔒</div>

                <br />To view Discover mode, you need to make your profile public!
              </div>
            </div>

          }
          {
            !isLoading && user.mentee_profile_public === 1 &&
            <div className="cards-container safe-area-bottom">
              { /* @ts-ignore: TS2739 */}
              <ReactSwing
                ref={this.stackRef}
                className="card-stack"
                setStack={this.handleSetStack}
                config={this.stackConfig}
                throwin={this.handleThrowIn}
                throwoutleft={(e: any) => this.handleCardThrowOut(e, ReactSwing.DIRECTION.LEFT)}
                throwoutright={(e: any) => this.handleCardThrowOut(e, ReactSwing.DIRECTION.RIGHT)}
                throwoutend={this.handleCardThrowOutEnd}
              >
                {
                  cards.map((card: User, index: number) => (
                    <div
                      className={`card-item${index < cards.length - 2 ? ' ion-hide' : ''}`}
                      key={card.id} data-card-id={card.id}
                    >
                      <SwipeCard
                        user={card}
                        onNoMoreSlide={this.handleNoMoreSlide}
                        onViewInfo={() => this.handleOpenProfile(card)}
                        onUndoCard={() => this.undoCard(card)}
                      />

                      {/* <div className="stamp stamp-like">Like</div>
                      <div className="stamp stamp-nope">Nope</div> */}
                    </div>
                  ))
                }
              </ReactSwing>

              <div className="card-actions">

                <IonGrid>
                  <IonRow className="ion-justify-content-center ion-align-items-center">

                    {/* <IonCol size="auto">
                      <IonButton fill='outline' color="gold" className="button-custom button-icon button-revert" onClick={ this.handleGetMoreCards }>
                        <IonIcon slot="icon-only" icon={refresh} />
                      </IonButton>
                    </IonCol> */}

                    {/* { user.current_role === 'mentee' && 
                        <>
                          <IonCol size="auto">
                            <IonButton fill='outline' color="dislike" className="button-custom button-icon button-dislike button-lg margin_right_100px" onClick={ this.handleClickNope }>
                              <IonIcon slot="icon-only" icon={closeSharp} />
                            </IonButton>
                          </IonCol>
                          <IonCol size="auto">
                            <IonButton fill='outline' color="like" className="button-custom button-icon button-like button-lg" onClick={ this.handleClickLike }>
                              <IonIcon slot="icon-only" icon={heartSharp} />
                            </IonButton>
                          </IonCol>
                        </>                    
                    } */}

                    {user.current_role === 'mentor' &&
                      <>
                        <IonCol size="auto">
                          <IonButton fill='outline' color="primary" className="button-custom button-icon button-lg margin_right_100px" onClick={this.shareMenteeProfile}>
                            <IonIcon slot="icon-only" icon={shareOutline} />
                          </IonButton>
                        </IonCol>
                        <IonCol size="auto">
                          <IonButton fill='outline' color="danger" className="button-custom button-icon button-lg" onClick={this.chatWithMentee}>
                            <IonIcon slot="icon-only" icon={chatbubble} />
                          </IonButton>
                        </IonCol>
                      </>
                    }
                    {/* <IonCol size="auto">
                      <IonButton fill='outline' color="like" className="button-custom button-icon button-star">
                        <IonIcon slot="icon-only" icon={star} />
                      </IonButton>
                    </IonCol> */}
                    {/* <IonCol  size="auto">
                      <IonButton fill='outline' color="boost" className="button-custom button-icon button-boost">
                        <IonIcon slot="icon-only" icon={flash} />
                      </IonButton>
                    </IonCol> */}
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          }
        </IonContent>

        <IonModal
          swipeToClose
          isOpen={isMatchModalOpen}
          enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}
        >
          <MatchedModal onClose={this.handleToggleMatchModal} user={this.state.other_user} goToChatWithUser={this.goToChatWithUser} />
        </IonModal>

        <IonModal
          swipeToClose
          isOpen={isMentorModalOpen}
          enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}
        >
          <MentorModal onClose={this.handleToggleMentorModal} user={this.state.other_user} goToChatWithUser={this.goToChatWithUser} findAMentor={this.findAMentor} />
        </IonModal>

        <IonModal
          swipeToClose
          isOpen={isInviteMentorModalOpen}
          enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}
        >
          <InviteMentorModal onClose={this.handleToggleInviteMentorModal} user={this.state.other_user} />
        </IonModal>

        <IonModal
          isOpen={isMenteeShareModalOpen}
          enterAnimation={enterAnimation} leaveAnimation={leaveAnimation}
        >
          <MenteeShareModal onClose={this.handleToggleMenteeShareModal} user={this.state.other_user} />
        </IonModal>

        <IonModal isOpen={isProfileOpen} swipeToClose>

          {
            (current_role === "mentor") &&

            <MentorProfile
              user={currentProfile}
              onClose={this.handleToggleProfile}
              history={this.props.history}
              // handleClickLike= { () => { return false } }
              // handleClickNope= { () => { return false } }
              handleClickNope={this.handleClickNope}
              handleClickLike={this.handleClickLike}
            />
          }

          {
            (current_role !== "mentor") &&

            <Profile
              user={currentProfile}
              onClose={this.handleToggleProfile}
              // handleClickLike= { () => { return false } }
              // handleClickNope= { () => { return false } }
              handleClickNope={this.handleClickNope}
              handleClickLike={this.handleClickLike}
              likeButtonsEnabled={true}
            />
          }
        </IonModal>

        {/* SEARCH MODAL */}
        <IonModal isOpen={true} className={searchModalClassName}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Filter Results</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => { this.toggleSearchModal(false); this.getData(); }}>Done</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="settings-page bg-light">

            <br />

            <IonSearchbar placeholder="Search" className="search-bar"
              onIonChange={({ detail }) => { this.setRangeValue("keywords", detail) }}
            // onIonInput={evt => changeSearchValue(evt)}
            ></IonSearchbar>

            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>Role</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonSelect placeholder="Choose role"
                  multiple={false}
                  value={this.state.searchQuery.role}
                  onIonChange={({ detail }) => { this.setRangeValue("role", detail) }}
                >
                  <IonSelectOption value="mentee">Mentee</IonSelectOption>
                  <IonSelectOption value="mentor">Mentor</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>

            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>Age</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonRange
                  dualKnobs={true}
                  pin={true}
                  pinFormatter={(value: number) => `${value}`}
                  min={18}
                  max={120}
                  onIonChange={({ detail }) => { this.setRangeValue("age", detail) }}
                >
                </IonRange>
              </IonItem>
            </IonList>


            <div className="cbutton_red margin_auto pointer width_90p" onClick={() => { this.toggleSearchModal(false); this.getData(); }}>Search</div>

          </IonContent>
        </IonModal>

      </IonPage>
    );
  }

};


export default (Discover);
