import React from "react";

import {
  IonContent,
  IonIcon,
  IonToolbar,
  IonInput,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { heart } from "ionicons/icons";
import LabelMatched from "../../components/LabelMatched/LabelMatched";
import "./MatchedModal.scss";

// import HeartsAnimation2 from '../../components/HeartsAnimation2/HeartsAnimation2';

import { useCallback } from "react";
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";

type Props = {
  user: any,
  onClose: () => void,
  goToChatWithUser: (user_id: string) => void
};

const MatchedModal: React.FC<Props> = ({ user, onClose, goToChatWithUser }) => {
  
  if(!user){
    user = {
      profile_image_url: "assets/img/people/12.jpeg",
      name: "Emily"
    };
  }  

  // console.log("MatchedModal user", user);

  const particlesInit = useCallback(async (engine: Engine) => {
      // console.log(engine);
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {
      await container;
      // await console.log(container);
  }, []);

  return (
    <IonContent force-overscroll="false" className="matched-modal-page">

      <Particles id="tsparticles" url="/js/confetti.json" init={particlesInit} loaded={particlesLoaded} />

      <div className="matched-container">
        <div
          style={{ backgroundImage: `url('${user.profile_image_url}')` }}
          className="background-img full-height z99"
        />
        <div className="matched-overlay">
        {/* <HeartsAnimation2 mode="many_hearts" /> */}
          
          {/* <LabelMatched /> */}

          <div className="matched-input z101">
            <div className="label">
              {/* <IonIcon icon={heart} /> */}
              <img src="assets/img/navigation/match.svg" width="24"/>
              <div>{user.name} likes you too!</div>
            </div>

            {/* <div className="ion-padding">
              <IonToolbar>
                <IonInput placeholder="Say something nice"></IonInput>
                <IonButtons slot="end">
                  <IonButton
                    type="submit"
                    fill="clear"
                    size="small"
                    color="primary"
                    onClick={sendMessage}
                  >
                    SEND
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </div> */}
          </div>
          <div className="display_block margin_top_80px z101">
            <IonButton shape="round" color="danger" className="width_60p margin_auto display_block" onClick={ () => { goToChatWithUser(user.id) } }>
              Chat with {user.name}
            </IonButton>
            {/* <IonButton fill="clear" color="primary" className="display_block" onClick={onClose}>
              Get a mentor to help you!
            </IonButton> */}
            <IonButton fill="clear" color="white" className="display_block" onClick={onClose}>
              Back to Courtli
            </IonButton>
          </div>
        </div>
      </div>
      
    </IonContent>
  );
};

MatchedModal.defaultProps = {};

export default MatchedModal;
