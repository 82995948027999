import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonFab,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonAvatar,
  IonPage
} from "@ionic/react";
import {
  arrowDown,
  checkmarkOutline,
  briefcaseOutline,
  locationOutline,
  musicalNote,
  play,
  closeSharp,
  star,
  heartSharp,
  chevronBack,
  filterSharp,
  closeOutline,
  closeCircleOutline,
  arrowUndo,
  glassesOutline,
  create
} from "ionicons/icons";
import ProfileImageSlides from "../../components/ProfileImageSlides/ProfileImageSlides";
import "./ProfilePreview.scss";
import {
  getActiveInterestsBadgesForProfile,
  decodeHtml
} from '../../apis/courtli/courtli';


type Props = {
  user: any;
  onClose: () => void;
  handleClickLike?: () => void;
  handleClickNope?: () => void;
  likeButtonsEnabled?: boolean;
};

const ProfilePreview: React.FC<Props> = ({ user, onClose, handleClickLike, handleClickNope, likeButtonsEnabled }) => {

  const [badges, setBadges] = useState<any>("None");
  const [likeButtonsEnabled2, setLikeButtonsEnabled2] = useState<boolean>(false);

  useEffect(() => {

    fetchData();

    async function fetchData() {
      let interests = await getActiveInterestsBadgesForProfile(user);
      let badges_new = interests.map((item: any, i: number) => {
        return <IonButton key={item.label} fill="outline" shape="round" color="medium" size="small">
          {item.label}
        </IonButton>
      });
      setBadges(badges_new);

      if (likeButtonsEnabled) {
        setLikeButtonsEnabled2(true);
      }
    }
  }, []);

  function handleClickWrapper(type: string) {
    console.log("Profile handleClickWrapper() type", type);
    if (handleClickLike && handleClickNope) {

      if (type === "LIKE") {
        onClose();
        handleClickLike();
      } else {
        onClose();
        handleClickNope();
      }
    }
  }

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (
    <>

      <IonPage className="profile-page">

        <IonHeader className='no-border'>
          <IonToolbar className='profile-toolbar toolbar-no-border toolbar-no-safe-area'>

            <IonButtons slot='start'>
              <IonButton onClick={onClose}>
                <div className='filter-button'>
                  <IonIcon icon={chevronBack} />
                </div>
              </IonButton>
            </IonButtons>

            <IonTitle className="ion-text-center">
              <div className="profile-name">
                {user.first_name || user.name}, {user.age}
              </div>
            </IonTitle>

            <IonButtons slot="end">
              <IonButton>
                <div className='filter-button'>
                  <IonIcon icon={filterSharp} />
                </div>
              </IonButton>
            </IonButtons>

          </IonToolbar>

          <div className="info-message" onClick={onClose}>
            <span className="dot"></span>
            <span>You are now in preview mode</span>
            <IonIcon icon={closeCircleOutline} />
          </div>

        </IonHeader>

        <IonContent className="profile-bg">

          <ProfileImageSlides images={user.images} />
          <div className="mask"></div>

          <IonCol className="card-action-buttons">
            <div className="circular-button">
              <IonIcon
                className="button-undo"
                icon={create}
                onClick={onClose}
              />
            </div>

            <div className="circular-button">
              <IonIcon
                className="button-info"
                icon={glassesOutline}
              />
            </div>
          </IonCol>

          <div className="profile-info border-bottom">
            <div className="profile-user-info">
              <div className="info-item">
                <IonIcon icon={briefcaseOutline} />{user.job_title}
              </div>
              {(user.location_description && user.location_description !== "Location unknown") &&
                <div className="info-item">
                  <IonIcon icon={locationOutline} />
                  {user.location_description} <img src={user.location_icon} height="12" className="margin_left_5px" />
                </div>
              }
            </div>
          </div>

          <div className="exit-message" onClick={onClose}>
            <span>Exit preview mode</span>
          </div>

        </IonContent>

      </IonPage >

    </>
  );
};

ProfilePreview.defaultProps = {};

export default ProfilePreview;
