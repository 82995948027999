import React from 'react';
import {
  IonAvatar,
  
} from '@ionic/react';
import {
  star
} from 'ionicons/icons';
import './Review.scss';
import { 
  getNiceMonthAndYear,
  getStarsFromRating
} from '../../apis/courtli/courtli';


type Props = {
  review: any,
  history: any,
  onClose: any
}

const Review: React.FC<Props> = ({ review, history, onClose }) => {
  return (
    <div key={review.id}>
      <div className="margin_auto text_align_center width_90p margin_top_40px margin_bottom_20px">
        <table className="width_100p">
          <tbody>
            <tr>
              <td className="width_10p">
                <IonAvatar onClick={ () => { history.push("/profile/" + review.user_id);onClose(); }  }>
                  <img src={ review.profile_image_url } alt="" />
                </IonAvatar>
              </td>
              <td>
                <div className="semibold t4 margin_left_10px text_align_left dark_gray">
                  {review.first_name}, {review.age}
                  <br/><span className="t5">{getStarsFromRating(review.rating)}</span>
                </div>
              </td>
              <td>
                <div className="t4 margin_left_10px text_align_right dark_gray">
                  { getNiceMonthAndYear(review.created_at)  }
                  <br/><span className="opacity_0p">EMPTY</span>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={3}>

                <div className="t4 text_align_left margin_top_10px dark_gray"> 
                  {review.review}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <img className="ctiny_avatar" src={review.profile_image_url} /> */}
        
      </div>
    </div>
  );
};

Review.defaultProps = {

}

export default Review;
