import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonLoading,
} from "@ionic/react";
import { logInOutline } from "ionicons/icons";
import "./Landing.scss";
// import HeartsAnimation from '../../components/HeartsAnimation/HeartsAnimation';

import { motion } from "framer-motion";
import {getVersion, setToken, getToken, getBrowser} from '../../apis/courtli/courtli';



type Props = {
  history: any;
};

const Landing: React.FC<Props> = ({ history }) => {

  const version = getVersion();
  let params = useParams<{ token: string }>();

  const goToLogin = () => {
    history.push("/login")
  }

  
  useEffect(() => {

    window.scrollTo(0, -1);
    if(params.token){
      setTokenAndReload(params.token);
    }

    async function setTokenAndReload(token:string){
      await setToken({token: token});
      await getToken();
      history.push("/wizards/setup");
    }
  }, []);

  async function refreshToLatestVersion(){
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
    }
    window.location.reload();
  }
  return (
    <IonPage>
      <IonContent forceOverscroll={false} className="landing-page bg-gradient">
        <div className="vertical-layout safe-area-bottom">
          
          <div className="z101">
            <div className="section-brand margin_top_80px">
                <img
                  className="logo-icon"
                  src="assets/img/courtli_logo_vertical.svg"
                  alt=""
                />
            </div>
            <div className="light t3 display_block text_align_center margin_top_20px">
                  Connection Refined
            </div>
          </div>
          
        {/* <HeartsAnimation mode="landing_page" /> */}
            

          <div className="section-buttons ion-padding z101">
            <div className="social-btns-holder">
              <IonButton
                expand="block"
                fill="outline"
                color="white"
                shape="round"
                className="button-facebook button-landing"
                onClick={goToLogin}
              >
                <IonIcon slot="start" icon={logInOutline} size="small" />
                SIGN IN
              </IonButton>

            </div>

            <div className="t5">
              {version} {getBrowser()} <div onClick={() => { refreshToLatestVersion() } } className="pointer display_inline_block">🔨</div>
            </div>
          </div>
        </div>
        
      </IonContent>
    </IonPage>
  );
};

Landing.defaultProps = {};

export default Landing;
