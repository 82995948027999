/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
// import './Styles.css'
const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Poppins", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    }
  },
};
//console.log("CardSection() CARD_ELEMENT_OPTIONS", CARD_ELEMENT_OPTIONS);

type Props = {
  stripe:any
}

const CardSection: React.FC<Props> = ({ stripe }) => {
  console.log("CardSection() stripe", stripe);
  return (
    <div className="width_90p margin_auto margin_top_40px">
      <div className="megaemoji margin_auto">💳</div>
      <div className="t1 margin_auto text_align_center margin_top_20px margin_bottom_80px">Add your card</div>

      <CardElement options={CARD_ELEMENT_OPTIONS} />
    </div>
  );
};

CardSection.defaultProps = {

}

export default CardSection;