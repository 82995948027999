import { IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonTextarea, IonTitle, IonToolbar, IonButtons, IonBackButton, IonRow } from '@ionic/react';
import { star, starOutline, chevronBack } from 'ionicons/icons';
import React, { useState } from 'react';
import { Icon } from 'ionicons/dist/types/components/icon/icon';
import { useParams } from "react-router";
import { useHistory } from 'react-router-dom';


import { 
  postReview
 } from '../../apis/courtli/courtli';

interface RatingProps {
  value: number;
  onChange: (value: number) => void;
}

const IonRating: React.FC<RatingProps> = ({ value, onChange }) => {
  const handleClick = (newValue: number) => {
    if (value !== newValue) {
      onChange(newValue);
    }
  };

  const stars = [];
  for (let i = 1; i <= 5; i++) {
    const filled = i <= value;
    if(filled){
      stars.push(
        <span key={i} onClick={() => handleClick(i)} >⭐</span>
      );
    }else{
      stars.push(
        <span key={i} onClick={() => handleClick(i)} className="grayscale_100p">⭐</span>
      );
    }
  }

  return (
    <div>
      {stars}
    </div>
  );
};

const ReviewsWrite: React.FC = () => {

  let params = useParams<{ order_id: string }>();
  let order_id = params.order_id;
  console.log("ReviewsWrite order_id", order_id);

  const [rating, setRating] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  const history = useHistory();

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  const handleSubmit = async () => {
    // handle submission logic here
    console.log("handleSubmit() rating", rating);
    console.log("handleSubmit() description", description);
    if(description.length < 10){
      alert("Please enter a review of at least 10 characters...");
      return;
    }
    let res = await postReview({
      order_id: order_id,
      rating: rating,
      review: description
    });
    if(res.response_code === 1){
      alert("Review saved successfully!");
      history.goBack();
    }else{
      alert("There was an error saving your review.");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="toolbar-no-border">
              <IonButtons slot="start">
                  <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
              </IonButtons>
              <IonTitle>
                Write a review
              </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <div className="t2 text_align_center margin_top_80px margin_bottom_20px">
          How was the service?
        </div>

        <div className="margin_auto width_90p">
          <IonTextarea 
            class="ctextarea"
                    
            placeholder="I thought the service was..."
            rows={4}
            value={description} 
            onIonChange={handleDescriptionChange} />
        </div>
        <div className="margin_auto width_100p text_align_center t1 margin_top_10px">
          <IonRating value={rating} onChange={handleRatingChange} />
        </div>
        
        
        <IonRow class="big_action_button">
            <div className="flex">
                <div className="cbutton_red margin_auto pointer cbutton_95p" onClick={handleSubmit}>Save Review</div>
            </div>
            {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
        </IonRow>

      </IonContent>
    </IonPage>
  );
};

export default ReviewsWrite;
