import React, { useState, useEffect } from "react";
import { IonRow, IonCol, IonIcon, IonButton } from "@ionic/react";
import {
  checkmarkOutline,
  ellipse,
  briefcaseOutline,
  locationOutline,
  glassesOutline,
  arrowUndo
} from "ionicons/icons";
import "./SwipeCard.scss";
import ProfileImageSlides from "../ProfileImageSlides/ProfileImageSlides";
import {
  courtli,
  getUserRoleDisplay,
  timeAgo,
  getActiveInterestsBadgesForProfile,
  decodeHtml
} from '../../apis/courtli/courtli';


type Props = {
  user?: any;
  isPreview?: boolean;
  onNoMoreSlide?: (l: boolean) => void;
  onViewInfo?: () => void;
  onUndoCard?: () => void;
};

const SwipeCard: React.FC<Props> = ({
  user,
  isPreview,
  onNoMoreSlide,
  onViewInfo,
  onUndoCard
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [badges, setBadges] = useState<any>("None");


  useEffect(() => {

    // console.log("SwipeCard useEffect() user", user);
    fetchData();

    user.about_me_short = user.about_me;
    if (user.about_me.length > 23) {
      user.about_me_short = user.about_me.substring(0, 20).trim() + "...";
    }

    async function fetchData() {
      let interests = await getActiveInterestsBadgesForProfile(user);

      let badges_new = interests.map((item: any, i: number) => {
        return <IonButton
          key={item.label}
          fill="outline"
          shape="round"
          color="white"
          size="small"
        >
          {item.label}
        </IonButton>
      });
      setBadges(badges_new);
    }
  }, []);

  const handleNoMoreSlide = (isOnTheLeft: boolean) => {
    if (onNoMoreSlide) onNoMoreSlide(isOnTheLeft);
  };

  const handleViewInfo = () => {
    if (onViewInfo) onViewInfo();
  };

  const handleUndoCard = () => {
    if (onUndoCard) onUndoCard();
  };

  const handleSlideChange = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="swipe-card">


      <ProfileImageSlides
        images={user.images}
        isClickable
        onNoMoreSlide={handleNoMoreSlide}
        onChange={handleSlideChange}
      />

      <div className="card-caption">

        <IonRow className="card-top-data">
          <span className="icon-verified">
            <IonIcon icon={checkmarkOutline} />
          </span>
          <span className="card-user-name">{user.name || user.first_name}, </span>
          <span className="card-user-age">{user.age}</span>
        </IonRow>

        <IonRow className="card-bottom-data">
          <IonCol>
            <div className="card-title">
            </div>

            {currentIndex === 0 && (
              <div className="card-user-info">
                <div>
                  <IonIcon icon={ellipse} color="success" />
                  {timeAgo(user.last_online_at)}
                </div>
                <div>
                  <IonIcon icon={briefcaseOutline} />
                  {decodeHtml(user.about_me_short)}
                </div>
                <div>
                  <IonIcon icon={locationOutline} />
                  {user.distance || "Distance"}
                </div>
              </div>
            )}

            {currentIndex === 1 && (
              <div className="card-user-info">
                <div className="passion-list">

                  {badges}
                </div>
              </div>
            )}

            {currentIndex > 1 && (
              <div className="card-user-info">
                <p>
                  {user.about_me}
                </p>
              </div>
            )}
          </IonCol>

        </IonRow>

      </div>

      <IonCol className="card-action-buttons">

        <div className="circular-button">
          <IonIcon
            className="button-undo"
            icon={arrowUndo}
            onClick={handleUndoCard}
          />
        </div>


        {!isPreview && (
          <div className="circular-button">
            <IonIcon
              className="button-info"
              icon={glassesOutline}
              onClick={handleViewInfo}
            />
          </div>
        )}
      </IonCol>


    </div>
  );
};

SwipeCard.defaultProps = {
  user: {},
  isPreview: false,
  onNoMoreSlide: () => { },
  onViewInfo: () => { },
};

export default SwipeCard;
