import React from "react";
import {
  IonPage,
  IonContent,
  IonIcon,
  IonToolbar,
  IonInput,
  IonButtons,
  IonButton,
  IonHeader,
  IonBackButton,
  IonTitle
} from "@ionic/react";
import {
  chevronBack,
} from "ionicons/icons";
import { heart, hourglassOutline } from "ionicons/icons";
import LabelMatched from "../../components/LabelMatched/LabelMatched";
import "./UnderConstruction.scss";

type Props = {
  history: any,
  onClose: () => void,
  goToChatWithUser: (user_id: string) => void,
  findAMentor: () => void,
};

const UnderConstruction: React.FC<Props> = ({ history }) => {
  // const user = {
  //   profile_image_url: "assets/img/people/12.jpeg",
  // };

  // console.log("MentorModal user", user);
  return (
    <IonPage>
      <IonHeader className="no-border">
          <IonToolbar className="user-profile-toolbar toolbar-no-border ">
              <IonButtons slot="start">
                  <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
              </IonButtons>
          </IonToolbar>
      </IonHeader>
      <IonContent force-overscroll="false" className="matched-modal-page">
        <div className="matched-container">
        
          <div className="mentor-overlay background_gradient">
            <div className="margin_auto">
              <div className="megaemoji margin_auto">🚧</div>
              <div className="margin_auto margin_top_5px t1 white text_align_center">Under Construction</div>
              <div className="margin_auto white text_align_center">
                  We are working on this, check back later!
              </div>
            </div>

            <div className="display_block margin_top_80px">
            <div className="matched-input">
              <div className="label">              
                <div>
                </div>
              </div>
            </div>
              <IonButton shape="round" color="light" className="width_60p margin_auto display_block margin_top_20px"
                onClick={ () => { history.goBack() }}>
                Go to profile
              </IonButton>
              {/* <IonButton fill="clear" color="primary" className="display_block" onClick={onClose}>
                Back to Courtli
              </IonButton>
              <IonButton fill="clear" color="primary" className="display_block" onClick={onClose}>
                Get a mentor to help you!
              </IonButton> */}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

UnderConstruction.defaultProps = {};

export default UnderConstruction;
