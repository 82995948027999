import React from 'react';
import { useState, useEffect } from 'react';
import {
  IonContent,
  IonText,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonModal,
  IonRow,
  IonPage,
  IonBackButton
} from '@ionic/react';
import {
  chevronBack
} from 'ionicons/icons';

import './Cards.scss';

import { 
  getCards,
  getLoadingIndicator,
  getNoResultsIndicator
 } from '../../apis/courtli/courtli';

import AddCardModal from '../../components/AddCardModal/AddCardModal';
import CardPreview from '../../components/CardPreview/CardPreview';

type Props = {
  onClose: () => void,
  service: any,
  history: any
}

const Cards: React.FC<Props> = ({ onClose, service, history }) => {

  const [showModal, setShowModal] = useState(false);
  let [ cards, setCards ] = useState<any>(false);

  useEffect(() => {
    fetchData();

  }, []);

  async function fetchData(){
    let cards_new = await getCards();
    
    console.log("Cards cards_new", cards_new);

    setCards(cards_new);
  }

  return (
    <IonPage>
      <IonHeader className="header-custom">
          <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
            </IonButtons>
            <IonTitle>Cards</IonTitle>
          </IonToolbar>
      </IonHeader>

      <IonContent forceOverscroll={false} scrollY={false} className="courtli-gold-page bg-light">

        <div className="height_80p overflow_y_auto">
          {
             cards === false ? getLoadingIndicator() : ( cards.length === 0 ? getNoResultsIndicator() : cards.map((card:any) => {
              return <CardPreview key={card.id} card={card} />
            }) ) 
          }

        </div>

        <IonRow class="big_action_button">
            <div className="flex">
                <div className="cbutton_red margin_auto pointer cbutton_95p" onClick={ () => { setShowModal(true) } }>
                    Add Card
                </div>
            </div>
        </IonRow>

        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <AddCardModal onClose={ () => { setShowModal(false); fetchData(); } } history={history} />
        </IonModal>
      </IonContent>
      
    </IonPage>
  );
};

Cards.defaultProps = {

}

export default Cards;
