
import React from 'react';
import {
  IonRow, IonCol, IonButton, IonIcon
} from '@ionic/react';
import {
  star
} from 'ionicons/icons';
// import './ServiceCard.scss';

import MentorAvatar from '../MentorAvatar/MentorAvatar';

import { getMentorLevel } from '../../apis/courtli/courtli';

type Props = {
  service: any,
  history: any
}

const ServiceTitle: React.FC<Props> = ({ service, history }) => {

  // if(!service){
  //   return (<></>);
  // }
  // console.log("ServiceTitle service", service);

  return (
    <div className="margin_auto margin_top_10px">
      <b className="t3">{service.name}</b>
      {/* <br/>{service.description} */}
      {/* <br/> */}
      <br />
      {/* <br/><IonButton onClick={ () => { openService(service.id) } }>View</IonButton> */}
      <table className="width_100p">
        <tbody>
          <tr>
            <td>
              <MentorAvatar user={service.user} history={history} />
            </td>
            <td>
              <div className="float_right t4">
                <b>⭐{Math.round(service.rating_stars * 10) / 10}</b> <span className="t5">({service.rating_count})</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ServiceTitle.defaultProps = {

}

export default ServiceTitle;


