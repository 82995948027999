

import React, { Component, useEffect,useState } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonPage, IonTitle, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton,
          IonHeader,
          IonToolbar,
          IonButtons

} from '@ionic/react';

import {
    close, personAdd, add, logoSnapchat, logoInstagram, logoRss, chevronBack
  } from 'ionicons/icons';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import {courtli, courtliHealthCheckWithRedirect, changeRole, initOnboarding, getUserRoleDisplay} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack } from 'ionicons/icons';
// import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
  history: any;
    user: any;
    onClose: () => void;
};

const PrivacyPolicy: React.FC<Props> = ({ history, user, onClose }) => {
  
    
    /// VARIABLES
    const [htmlContent, setHtmlContent] = useState("");
    
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {
        fetch("assets/docs/privacy-policy.html")
          .then((response) => response.text())
          .then((data) => {
            setHtmlContent(data);
          });
        
    }, []);
    

    return (
        <>
            <IonPage className="backround_almost_white">
                <IonHeader className="header-custom">
                    <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
                    </IonButtons>
                    <IonTitle>Privacy policy</IonTitle>
                    {/* <IonButtons slot="end">
                        <IonButton color="primary" onClick={ history.goBack() }>
                        Done
                        </IonButton>
                    </IonButtons> */}
                    </IonToolbar>
                </IonHeader>
                {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}
                <IonContent className="ion-padding">
                    <IonRow>                        
                        <h1>Privacy Policy</h1>
                        
                    </IonRow>
                    {/* <IonRow>
                        <p>
                            You can find our privacy policy <a target="_blank" href="https://docs.google.com/document/d/157ewDbnab7oUdf6gjkAmBEcor0U8Ut3g/edit?usp=sharing&ouid=116394969839320496876&rtpof=true&sd=true">here</a>.
                            <br/>In case of questions do not hesitate to contact support@courtli.com.
                        </p>
                    </IonRow> */}

                    <IonRow>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                        
                    </IonRow>
                    
                </IonContent>
            </IonPage>
        </>
    )
}

PrivacyPolicy.defaultProps = {};

export default PrivacyPolicy;