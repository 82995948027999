import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
  IonTitle
} from "@ionic/react";
import {
  chevronBack
} from "ionicons/icons";

import { 
  getFriendUser,
  getUserImages,
  bringProfileImageToTheTopAndTag,
  goBack
 } from '../../apis/courtli/courtli';
import Profile from '../Profile/Profile';

type Props = {
  history:any
};

const ProfilePage: React.FC<Props> = ({history}) => {

  
  let params = useParams<{ user_id: string }>();
  let user_id = params.user_id;
  console.log("ProfilePage user_id", user_id);
  
  let [user, setUser] = useState<any>(false);

   
  useEffect(() => {

    fetchData();  
    
  }, []);

  async function fetchData(){
    // await getUser();
    let user_new = await getFriendUser({friend_user_id: user_id});
    console.log("ProfilePage fetchData() user_new", user_new);
    if(user_new){
      let images = await getUserImages({user_id: user_id});
      console.log("ProfilePage fetchData() images", images);
      images.map((image:any) => {
          image.imageUrl = image.url
      });
      user_new.images = images;
      user_new.images = bringProfileImageToTheTopAndTag(user_new);
      setUser(user_new);
    }else{
      alert("Sorry, this user is not found.");
    }
  }

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (
    
    <IonPage>
      {user && 
          <Profile 
            user={user} 
            onClose={()=>{ goBack(history, {refresh: false}) }} 
            likeButtonsEnabled={false}
          />
      }

      
    
    </IonPage>
  );
};

ProfilePage.defaultProps = {};

export default ProfilePage;
