// @ts-nocheck

import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonAvatar,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToggle,
  IonNote,
  IonTextarea
} from "@ionic/react";
import {
  accessibility,
  addOutline,
  briefcase,
  calendarOutline,
  chevronBack, chevronExpand, closeCircle, closeCircleOutline, create, fingerPrint, flashOff, flashOffOutline, heartDislikeCircleOutline, lockClosedSharp, maleFemaleOutline, notificationsOffOutline, rocket, sadOutline, school, skullOutline,
} from "ionicons/icons";
import "./ReportUser.scss";


type Props = {
  user: any;
  onClose: () => void;
};

const ReportUser: React.FC<Props> = ({ user, onClose }) => {

  //SELECT REASON
  var reasonList = [
    { text: "Dangerous or illegal behaviour", icon: skullOutline, active: false },
    { text: "Discriminatory behaviour", icon: flashOffOutline, active: false },
    { text: "Misinformation", icon: notificationsOffOutline, active: false },
    { text: "Racial profiling", icon: heartDislikeCircleOutline, active: false },
    { text: "Disrespectful behaviour", icon: sadOutline, active: false }
  ];
  const [reasons, setReasonList] = React.useState([]);
  var reasonItems = "";
  setReasonItems();

  function setReasonItems() {
    reasonItems = reasons.map((reason: any, i: number) =>
      <IonItem key={i} lines="none" className={reason.active ? "active" : ""} onClick={() => { handleToggleReason(i) }}>
        <IonIcon icon={reason.icon} slot="start" />
        <IonNote>{reason.text}</IonNote>
      </IonItem >
    );
  }

  function handleToggleReason(i) {
    for (let reason of reasonList) {
      reason.active = false;
    }
    reasonList[i].active = true;
    setReasonList(reasonList);
    setReasonItems();
    setActiveTextReason(false);
    setReason("");
  }

  function disableAllReasons() {
    for (let reason of reasonList) {
      reason.active = false;
    }
    setReasonList(reasonList);
    setActiveTextReason(true);
  }

  //TEXT REASON
  const [reason, setReason] = useState("");
  const [reasonCount, setReasonCount] = useState(0);
  const maxCount = 300;

  useEffect(() => {
    setReasonCount(reason.toString().length);
  }, [reason]);


  //ENABLED REASON
  const [activeTextReason, setActiveTextReason] = useState(false);


  //INIT
  useEffect(() => {
    setReasonList(reasonList);
  }, []);

  //TODO SEND REPORT TO BACKEND

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (
    <>

      <IonHeader className="no-border">
        <IonToolbar className="toolbar-no-border">
          <IonTitle></IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="report-page bg-light">

        <div className="full-height">

          <IonRow>
            <div className="top-emoji">🤬</div>
          </IonRow>
          <IonRow>
            <div className="top-title">
              Delete & report
            </div>
          </IonRow>
          <IonRow>
            <div className="top-description">Let us know what this user did wrong</div>
          </IonRow>

          <div className="segment-view">

            <IonList className="list-custom">

              {reasonItems}

              <IonItem className='variable-height' lines="none" className={activeTextReason ? "active" : ""}>
                <IonIcon icon={closeCircleOutline} slot="start" />
                <IonTextarea placeholder="Other reason..." rows={4} value={reason}
                  onIonInput={(e) => setReason(e.target.value)}
                  maxLength={maxCount} onClick={() => { disableAllReasons() }} />
                <IonNote className="counter-text" slot="end">{reasonCount}/{maxCount}</IonNote>
              </IonItem>

            </IonList>

            <div className="bottom-actions">
              <div className="continue-button" onClick={onClose}>
                <span>DELETE & SUBMIT REPORT</span>
              </div>
              <span className="skip-text" onClick={onClose}>I changed my mind</span>
            </div>

          </div>
        </div>

      </IonContent>
    </>
  );


};

ReportUser.defaultProps = {};

export default ReportUser;
