import React, { useState } from "react";
import {
  IonContent,
  IonIcon,
  IonToolbar,
  IonInput,
  IonButtons,
  IonButton,
} from "@ionic/react";
import "./MenteeShareModal.scss";

import { shareMenteeProfile, validateEmail } from '../../apis/courtli/courtli';

type Props = {
  user: any,
  onClose: () => void
};

const MenteeShareModal: React.FC<Props> = ({ user, onClose }) => {
  

  // console.log("MenteeShareModal user", user);
  /// VARS
  let [email, setEmail] = useState("");

  /// FUNCTIONS
  async function shareMenteeProfileInit(){
    console.log("inviteAFriend() email", email);

    if(validateEmail(email)){
      let res = await shareMenteeProfile(email, user.id);
      onClose();
    }else{
      alert("Please provide a valid email!");
    }
  }
  return (
    <IonContent force-overscroll="false" className="matched-modal-page">
      <div className="matched-container">
        <div
          style={{ backgroundImage: `url('${user.profile_image_url}')` }}
          className="background-img full-height z99"
        />
       
        <div className="matched-overlay">
          <div className="margin_auto">
            <div className="megaemoji margin_auto">👩‍❤️‍👩</div>
            <div className="margin_auto margin_top_5px t1 white text_align_center">Sharing is caring</div>
            <div className="margin_auto white text_align_center t3">
                Do you know someone who
                <br/>would be a great match for {user.first_name}?               
            </div>
            
            <div className="margin_auto margin_top_40px color_dark_gray text_align_center width_60p">
              <IonInput
                    className="cinput"
                    placeholder="Match email"
                    onIonChange={ (e:any) => { 
                        // console.log("PhoneWizard Code onChange() e.target.value", e.target.value);
                        setEmail(e.target.value);
                    }}
                />
            </div>
          </div>

          <div className="display_block margin_top_80px">
          <div className="matched-input">
            <div className="label">              
              <div>
              </div>
            </div>
          </div>
          
            <IonButton shape="round" color="primary" className="width_60p margin_auto display_block margin_top_20px" onClick={shareMenteeProfileInit}>
              Share
            </IonButton>
            <IonButton fill="clear" color="light" className="display_block" onClick={onClose}>
              Back to Courtli
            </IonButton>
            {/* <IonButton fill="clear" color="primary" className="display_block" onClick={onClose}>
              Get a mentor to help you!
            </IonButton> */}
          </div>
        </div>
      </div>
    </IonContent>
  );
};

MenteeShareModal.defaultProps = {};

export default MenteeShareModal;
