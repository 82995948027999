
import React, { Component, useEffect,useState } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton,
          IonButtons

} from '@ionic/react';
///import { Plugins } from '@capacitor/core';
import { findByTestId } from '@testing-library/react';
import {courtli, courtliHealthCheckWithRedirect, changeUserField,
    getOnboardingNextStep,
    getUser} from '../../apis/courtli/courtli';
import { Geolocation } from '@capacitor/geolocation';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack } from 'ionicons/icons';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
  history: any;
    user: any;
    onClose: () => void;
};

const LocationWizard: React.FC<Props> = ({ history, user, onClose }) => {
  
    
    /// VARIABLES
    
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {
        courtliHealthCheckWithRedirect(history);
    }, [])


    const shareLocation = async () => {


        /// FOR SIMPLICITY ALWAYS USE IPGEOLOCATION
            let location = await getLocationFromIP();
            console.log('shareLocation() IPGEO coordinates', location);
            await saveLocation(location);
            goToNextOnboardingStep();

        // alert("Searching for your location - this might take a second.");

        // try{
        //     const coordinates = await Geolocation.getCurrentPosition();
        
        //     console.log('shareLocation() GPS coordinates', coordinates);

        //     let location = {
        //         lat: coordinates.coords.latitude,
        //         lon: coordinates.coords.longitude
        //     };

        //     await saveLocation(location);
        //     goToNextOnboardingStep();
            
        // }catch(err:any){
        //     console.log("shareLocation() err.message", err.message);
        //     console.log("Sorry, there has been an error while sharing your location. Contact support at support@courtli.com. Error message: " + err.message);

        
        //     /// IF GPS FAILS, USE IP GEOLOCATION
        //     let location = await getLocationFromIP();
        //     console.log('shareLocation() IPGEO coordinates', location);
        //     await saveLocation(location);
        //     goToNextOnboardingStep();
        // }
    };

    const getLocationFromIP = async () => {

        let url = courtli.server.url + "/users/geolocate";
        url += "?token=" + courtli.token.token;
        let res:any = await fetch(url);
        res = await res.json();
        console.log("getLocationFromIP() response", res);

        let location_description = getLocationDescriptionFromGeolocateResponse(res);
        let location_icon = res.data.country_flag;


        
        let location = {
            lat: 0,
            lon: 0,
            location_description: location_description,
            location_icon: location_icon
        };

        if(res.data && res.data.latitude){
            location.lat = res.data.latitude;
            location.lon = res.data.longitude;
        }
        return location;
    }

    const getLocationDescriptionFromGeolocateResponse = (res:any) => {
        
        let location_description = "Location unknown";
        if(res.data && res.data.city && res.data.country_name){

            if(res.data.country_name === "United States"){
                location_description = res.data.city + ", " + res.data.state_prov;
            }else{
                location_description = res.data.city + ", " + res.data.country_name;
            }
        }
        return location_description;
    }

    const saveLocation = async (location: any) => {
        /// SAVE LOCATION MEANS UPDATING 3 DIFFERENT FIELDS
        let r1 = await changeUserField("lat", location.lat);
        let r2 = await changeUserField("lon", location.lon);
        let r4 = await changeUserField("location_description", location.location_description);
        let r5 = await changeUserField("location_icon", location.location_icon);
        let r3 = await changeUserField("last_location_read_at", new Date().getTime());
        console.log("changeUserField() results", {
            r1: r1,
            r2: r2,
            r3: r3,
            r4: r4,
            r5: r5
        });

        return true;        
    }

    const goToNextOnboardingStep = async () => {
        /// REDIRECT
        // history.goBack();
        /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
        let user_new = await getUser({use_cache: false});
        console.log("LocationWizard() user_new", user_new);
        let next_step = getOnboardingNextStep(user_new);
        console.log("LocationWizard() next_step", next_step);
        history.push(next_step.wizard);
    }
    
    return (
      <IonPage className="backround_almost_white">
        <IonHeader className="no-border">
            <IonToolbar className="user-profile-toolbar toolbar-no-border ">
            <IonButtons slot="start">
                <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
            </IonButtons>
            {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
            <IonButtons slot="end">
                <IonButton></IonButton>
            </IonButtons>
            </IonToolbar>
        </IonHeader>
        {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}
        <IonContent className="ion-padding">
            
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px white font_size_24px">🌎</div>
            </IonRow>
            {/* <IonRow>
                <div className="megaemoji margin_auto margin_top_80px">
                    <img src="https://upload.wikimedia.org/wikipedia/commons/b/bf/GIF_Mundo_Banderas.gif" />
                </div>
            </IonRow> */}
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray">
                    Add your location
                </div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                    You can update your location any time to see matches and mentors nearby!
                </div>
            </IonRow>
            <IonRow class="wizard_action_row">
                <div className="flex">
                    <div className="margin_auto cbutton_red pointer" onClick={ () => ( shareLocation() ) }>Share location</div>
                </div>
                {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
            </IonRow>
            


            
            <IonRow>
                {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
          </IonRow>
        </IonContent>
      </IonPage>
    )
}

LocationWizard.defaultProps = {};

export default LocationWizard;