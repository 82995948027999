export default [
  {
    id: 0,
    from: 0,
    isSender: true,
    // avatar: 'https://courtli.s3.us-east-1.amazonaws.com/static/default_profile_image.jpeg',
    type: 'image',// text || image
    body: '{{profile_image_url}}',
    timestamp: new Date(),
    created_at: new Date()
  },
  // {
  //   id: 1,
  //   from: 0,
  //   isSender: true,
  //   // avatar: 'https://courtli.s3.us-east-1.amazonaws.com/static/default_profile_image.jpeg',
  //   type: 'text',// text || image
  //   body: 'Hi, my name is {{first_name}}! I just joined Courtli!',
  //   timestamp: new Date()
  // },  
  {
    id: 2,
    from: 0,
    isSender: false,
    avatar: 'https://courtli.s3.us-east-1.amazonaws.com/static/default_profile_image.jpeg',
    type: 'text',// text || image
    body: 'Welcome to Courtli {{first_name}}! This is where you will find the most important updates and reminders.',
    timestamp: new Date(),
    created_at: new Date()
  },  
  {
    id: 4,
    from: 0,
    isSender: false,
    avatar: 'https://courtli.s3.us-east-1.amazonaws.com/static/default_profile_image.jpeg',
    type: 'text',// text || image
    body: 'Here is the checklist you should follow \n\n{{onboarding_checklist}}\n\nAny questions?',
    timestamp: new Date(),
    created_at: new Date()
  }
];
