import React from "react";
import {
  IonContent,
  IonIcon,
  IonToolbar,
  IonInput,
  IonButtons,
  IonButton,
} from "@ionic/react";
import { heart, hourglassOutline } from "ionicons/icons";
import LabelMatched from "../../components/LabelMatched/LabelMatched";
import "./MentorModal.scss";

type Props = {
  user: any,
  onClose: () => void,
  goToChatWithUser: (user_id: string) => void,
  findAMentor: () => void,
};

const MentorModal: React.FC<Props> = ({ user, onClose, goToChatWithUser, findAMentor }) => {
  // const user = {
  //   profile_image_url: "assets/img/people/12.jpeg",
  // };

  // console.log("MentorModal user", user);
  return (
    <IonContent force-overscroll="false" className="matched-modal-page">
      <div className="matched-container">
       
        <div className="mentor-overlay background_gradient">
          <div className="margin_auto">
            <div className="megaemoji margin_auto">🥳</div>
            <div className="margin_auto margin_top_5px t1 white text_align_center">Woohoo!</div>
            <div className="margin_auto white text_align_center">
                No more swiping!
                <br/>Our mentors can vet matches for you!
            </div>
          </div>

          <div className="display_block margin_top_80px">
          <div className="matched-input">
            <div className="label">              
              <div>
              </div>
            </div>
          </div>
            <IonButton shape="round" color="light" className="width_60p margin_auto display_block margin_top_20px" onClick={findAMentor}>
              Find a mentor
            </IonButton>
            <IonButton fill="clear" color="light" className="display_block" onClick={onClose}>
              Back to Courtli
            </IonButton>
            {/* <IonButton fill="clear" color="primary" className="display_block" onClick={onClose}>
              Get a mentor to help you!
            </IonButton> */}
          </div>
        </div>
      </div>
    </IonContent>
  );
};

MentorModal.defaultProps = {};

export default MentorModal;
