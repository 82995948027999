import React, { useState, useEffect } from 'react';
import {
  IonPage, IonHeader, IonToolbar, IonSegment, IonSegmentButton, IonLabel, IonContent, IonText, IonButton, IonRow, IonCol,
  IonButtons, IonAvatar, IonTitle, IonModal,
  IonList,
  IonListHeader,
  IonItem,
  IonNote,
  IonRange,
  IonIcon,
  IonSelect,
  IonSelectOption,
  IonSearchbar
} from '@ionic/react';
import PersonCard from '../../components/PersonCard/PersonCard';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import OrderCard from '../../components/OrderCard/OrderCard';
import ServiceTitle from '../../components/ServiceTitle/ServiceTitle';

import './Services.scss';
// import USERS from '../Discover/users.dummy';

import { getUser, getServices, getNoResultsIndicator, getOrders } from '../../apis/courtli/courtli';

//ICONS
import { searchSharp, filterSharp } from 'ionicons/icons';


type Props = {
  history: any
}

const Services: React.FC<Props> = ({ history }) => {


  const [searchModalClassName, setSearchModalClassName] = useState<string>("display_none");
  const [searchQuery, setSearchQuery] = useState<any>({});
  const [searchModalIsOpen, setSearchModalIsOpen] = useState<boolean>(false);
  const [segmentView, setSegmentView] = useState<string>('SERVICES');
  // const mentors: any[] = [...USERS];
  // const [coaches, setCoaches] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const [orders, setOrders] = useState<any>([]);
  // let coaches: any[] = [...USERS].reverse();
  // const courses: any[] = [...USERS].reverse();
  // const gurus: any[] = [...USERS].sort((a:any, b:any) => {return a.age - b.age});
  const [user, setUser] = useState<any>(false);

  useEffect(() => {

    setSearchModalIsOpen(true);
    fetchData();
  }, []);

  async function fetchData() {

    // console.log("Services fetchData() fetching user");
    let user_new = await getUser({ use_cache: true });
    // console.log("Services fetchDate() user_new", user_new);
    setUser(user_new);

    // let coaches_new = await getCards({
    //   role: "mentor"
    // });
    // setCoaches(coaches_new);

    /// FOR MENTORS
    if (user_new.current_role === "mentor") {
      setSegmentView("MY_SERVICES");
      runSearchForServicesTab("MY_SERVICES", user_new);
    } else {
      setSegmentView("SERVICES");
      runSearchForServicesTab("SERVICES");
    }
    fetchOrders(user_new);
  }

  async function fetchOrders(user: any) {

    //console.log("Services fetchOrders() user", user);
    if (user.current_role === "mentor") {
      searchQuery.mentor_id = user.id;
    } else {
      searchQuery.mentee_id = user.id;
    }
    //console.log("Services fetchServices() searchQuery", searchQuery);
    let orders_new = await getOrders({
      search: searchQuery
    });
    //console.log("Services fetchServices() orders_new", orders_new);
    setOrders(orders_new);
  }

  async function fetchServices() {

    // console.log("Services fetchServices() searchQuery", searchQuery);
    let services_new = await getServices({
      // type: "courses"
      search: searchQuery
    });
    // console.log("Services fetchServices() services_new", services_new);
    setServices(services_new);
  }

  function openService(id: any) {
    history.push("/service/" + id);
  }

  function setRangeValue(label: string, detail: any) {
    // console.log("setRangeValue()", {label: label, detail: detail});
    let sq = searchQuery;
    let value = detail.value;
    if (label === "rating") {
      value.upper = value.upper / 10;
      value.lower = value.lower / 10;
    }
    sq[label] = value;
    setSearchQuery(sq);

    // console.log("searchQuery", searchQuery);
  }

  function toggleSearchModal(state: boolean) {
    if (state) {
      setSearchModalClassName("display_flex");
    } else {
      setSearchModalClassName("display_none");
    }
  }

  function runSearchForServicesTab(segment?: string, user_new?: any) {
    /// OPPOSITE DUE TO ASYNC I THINK
    // console.log("runSearchForServicesTab() user_new", user_new);
    let u = user_new || user;
    // console.log("runSearchForServicesTab() u", u);
    let seg = segment || segmentView;
    if (seg === 'SERVICES') {
      setRangeValue("user_id", false); /// ANY
      fetchServices();
    } else {
      setRangeValue("user_id", { value: u.id }); /// ONLY MINE
      fetchServices();
    }
  }

  function editService(id: any) {
    history.push("/service/" + id + "/edit");
  }

  function viewOrder(id: any) {
    console.log("Services viewOrder() id", id);
    history.push("/order/" + id);
  }

  function addNewService() {
    history.push("/service/new/edit");
  }



  //SEARCH
  function changeSearchValue(evt: any) {
    let value = evt.target.value;
    console.log(value);
  }


  return (

    <IonPage className="services-page">

      <IonHeader className='no-border'>
        <IonToolbar className='toolbar-no-border toolbar-no-safe-area'>

          <IonButtons slot='start'>
            <IonAvatar onClick={() => { history.push("/tabs/profile") }}>
              <img src={user.profile_image_url} alt="" />
            </IonAvatar>
          </IonButtons>

          <IonSearchbar placeholder={"Search for services"} className="search-bar" onIonInput={evt => changeSearchValue(evt)}></IonSearchbar>

          <IonButtons slot='end'>
            <IonButton onClick={() => { toggleSearchModal(true); }}>
              <div className='circular-button'>
                <IonIcon icon={searchSharp} />
              </div>
            </IonButton>
            <IonButton>
              <div className='circular-button' onClick={() => { toggleSearchModal(true); }}>
                <IonIcon icon={filterSharp} />
              </div>
            </IonButton>
          </IonButtons>

        </IonToolbar>
      </IonHeader>


      <IonContent className="safe-area-bottom">

        {user.current_role === "mentor" &&
          <IonSegment className="segment-custom"
            value={segmentView}
            onIonChange={e => { setSegmentView(e.detail.value as string); runSearchForServicesTab(); }}
            mode="md">
            <IonSegmentButton value="MY_SERVICES">
              <IonLabel>My Services</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="MY_ORDERS">
              <IonLabel>My Orders</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        }

        <div className='services-container'>

          {
            segmentView === 'SERVICES' &&
            <div className="segment-view">

              <IonRow>

                {
                  services.length === 0 ? getNoResultsIndicator() :
                    services.map((service: any) => {

                      let match_count = Math.round(Math.random() * 5 + 1);

                      /// DATA HAS BEEN JOINED WITH USER SERVER SIDE
                      service.user = {
                        id: service.user_id,
                        first_name: service.first_name,
                        age: service.age,
                        profile_image_url: service.profile_image_url,
                        last_online_at: service.last_online_at,
                        level: service.level
                      };

                      return <div key={service.id} className="card-container" >
                        <ServiceCard service={service} history={history} isEditCard={false} />
                      </div>

                    })
                }
              </IonRow>
            </div>
          }

          {
            segmentView === 'MY_SERVICES' &&
            <div className="segment-view">

              <IonRow className="height_70vh overflow_y_auto padding_bottom_70px">

                {
                  services.length === 0 ? getNoResultsIndicator() :
                    services.map((service: any) => {

                      let match_count = Math.round(Math.random() * 5 + 1);

                      /// DATA HAS BEEN JOINED WITH USER SERVER SIDE
                      service.user = {
                        id: service.user_id,
                        first_name: service.first_name,
                        age: service.age,
                        profile_image_url: service.profile_image_url,
                        last_online_at: service.last_online_at,
                        level: service.level
                      };


                      return <div key={service.id} className="card-container" >
                        <ServiceCard service={service} history={history} isEditCard={true} />
                      </div>

                    })
                }
              </IonRow>
              <IonRow class="big_action_button">
                <div className="flex">
                  <div className="add-button cbutton_red margin_auto pointer cbutton_95p" onClick={() => (addNewService())}>Add new</div>
                </div>
                {/* <div className="margin_auto  cbutton_red pointer" onClick={ () => ( changeRoleAndRedirect("default") ) }>{ getUserRoleDisplay("default") }</div> */}
              </IonRow>
            </div>
          }
          {
            segmentView === 'MY_ORDERS' &&
            <div className="segment-view">

              <IonRow>

                {
                  orders.length === 0 ? getNoResultsIndicator() :
                    orders.map((order: any) => {

                      let match_count = Math.round(Math.random() * 5 + 1);

                      /// DATA HAS BEEN JOINED WITH USER SERVER SIDE
                      order.user = {
                        id: order.user_id,
                        first_name: order.first_name,
                        age: order.age,
                        profile_image_url: order.profile_image_url,
                        last_online_at: order.last_online_at,
                        level: order.level
                      };

                      return <div key={order.id} className="width_95p margin_auto margin_bottom_40px">
                        <OrderCard order={order} history={history} viewer={user} mode="MY_ORDERS" />
                      </div>
                    })
                }
              </IonRow>
            </div>
          }
        </div>


        {/* SEARCH MODAL */}
        <IonModal isOpen={searchModalIsOpen} className={searchModalClassName}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Filter Services</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => { fetchServices(); toggleSearchModal(false); }}>Done</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>



          <IonContent className="safe-area-bottom settings-page bg-light">

            <br />

            <IonSearchbar placeholder="Search services" className="search-bar"
              onIonChange={({ detail }) => { setRangeValue("keywords", detail) }}
            // onIonInput={evt => changeSearchValue(evt)}
            ></IonSearchbar>

            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>Type of service</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonSelect placeholder="Choose services" multiple={true}
                  onIonChange={({ detail }) => { setRangeValue("services", detail) }}
                >
                  <IonSelectOption value="course">Courses</IonSelectOption>
                  <IonSelectOption value="coaching">Coaching</IonSelectOption>
                  <IonSelectOption value="match_finding">Match finding</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>

            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>Price 💲</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonRange
                  dualKnobs={true}
                  pin={true}
                  pinFormatter={(value: number) => `${value}$`}
                  min={0}
                  max={500}
                  onIonChange={({ detail }) => { setRangeValue("price", detail) }}
                >
                </IonRange>
              </IonItem>
            </IonList>


            <IonList className="list-custom">
              <IonListHeader>
                <IonLabel>Rating ⭐</IonLabel>
              </IonListHeader>
              <IonItem>
                <IonRange
                  dualKnobs={true}
                  pin={true}
                  pinFormatter={(value: number) => `${value / 10} ⭐`}
                  min={0}
                  max={50}
                  onIonChange={({ detail }) => { setRangeValue("rating", detail) }}
                >
                </IonRange>
              </IonItem>
            </IonList>

            <div className="cbutton_red margin_auto pointer width_90p" onClick={() => { fetchServices(); toggleSearchModal(false); }}>Search</div>

          </IonContent>
        </IonModal>


      </IonContent>
    </IonPage>
  );

};

Services.defaultProps = {

}

export default Services;
