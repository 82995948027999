import React from 'react';

import './CardPreview.scss';
import { 
  getCardIconUrl
 } from '../../apis/courtli/courtli';
type Props = {
    card:any
}

const CardPreview: React.FC<Props> = ({ card }) => {

    let last4 = card.last4;
    let brand = card.brand;

  const iconUrl = getCardIconUrl(brand);
  const cardNumber = last4 ? `**** **** **** ${last4}` : '**** **** **** ****';

  return (
    <div className="credit-card-preview">
      <div className="credit-card-logo">
        <img src={iconUrl} alt={`${brand} logo`} />
      </div>
      <div className="credit-card-number">
        {cardNumber}
      </div>
      <div className="credit-card-country">
        Country: {card.country}
      </div>
      {
        !card.livemode &&
        <div className="credit-card-test-badge">TEST CARD</div>
      }
    </div>
  );
};

export default CardPreview;
