
import React, { Component, useEffect,useState } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton,
          IonInput,
          IonButtons,
          IonTextarea

} from '@ionic/react';

import {courtli, courtliHealthCheckWithRedirect, initOnboarding, changeUserField, goBack, getUser, getOnboardingNextStep} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack, chevronBack } from 'ionicons/icons';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
    history: any;
    onClose: () => void;
};

const AboutMeWizard: React.FC<Props> = ({ history, onClose }) => {
  
    
    /// VARIABLES
    let [aboutMe, setAboutMe] = useState("");
    let [characterCounterText, setCharacterCounterText] = useState("");
    // let [nextStep, setNextStep] = useState<any>({
    //     title: "START NOW",
    //     wizard: "/tabs/profile"
    // });
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {
        fetchData();     
    }, [])
    async function fetchData(){

        let user = await getUser({use_cache: false});
        console.log("NameWizard fetchData() user", user);
        setAboutMe(user.about_me);
        courtliHealthCheckWithRedirect(history);
        // initOnboarding(setNextStep);   
    }

    function checkCharactersAndSetAboutMe(value: string){
        setAboutMe(value);
        setCharacterCounterText(value.length + "/300 characters");

    }

    async function saveAboutMe(){

        if(300 <= aboutMe.length && aboutMe.length  <= 1000){
            let result = await changeUserField("about_me", aboutMe);
            if(result.response_code === 1){
                // goBack(history, {refresh: true});
                
                /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
                let next_step = getOnboardingNextStep(await getUser({use_cache: false}));
                console.log("saveBirthdayAndProceed() changeUserField() next_step", next_step);
                history.push(next_step.wizard);
            }else{
                alert("We could not save your name - make sure there are only letters in it.");
            }
        }else{
            alert("Your about me section must be between 300 and 1000 characters long.");
        }
        
    }
    return (
      <IonPage className="backround_almost_white">
        <IonHeader className="no-border">
            <IonToolbar className="user-profile-toolbar toolbar-no-border ">
            <IonButtons slot="start">
                <IonBackButton text={""} icon={chevronBack} defaultHref="tabs" />
            </IonButtons>
            {/* <IonTitle className="ion-text-center">Profile</IonTitle> */}
            <IonButtons slot="end">
                <IonButton></IonButton>
            </IonButtons>
            </IonToolbar>
        </IonHeader>
        {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}
        <IonContent className="ion-padding">
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px">✍</div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray text_align_center">
                    Be yourself
                </div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_20px text_align_center t4 color_light_gray">
                    What lights you up?
                    <br/>Who do you want to meet?
                    <br/>If you need assistance, reach out to one of our mentors.
                </div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_20px color_dark_gray width_90p">
                    <IonTextarea
                        class="ctextarea"                  
                        placeholder="About you..."
                        value={aboutMe}
                        rows={4}
                        onIonChange={ (e:any) => { 
                            // console.log("PhoneWizard Code onChange() e.target.value", e.target.value);
                            checkCharactersAndSetAboutMe(e.target.value);
                        }}
                    />
                    <div className="margin_top_20px text_align_center t4 color_light_gray">{ characterCounterText }</div>
                    {/* <div className="margin_auto  cbutton_red pointer" onClick={ () =>  { checkSMSCode(); } }>Verify</div> */}
                </div>
            </IonRow>
            <IonRow class="wizard_action_row">
                <div className="flex">
                    <div className="margin_auto  cbutton_red pointer" onClick={ () => ( saveAboutMe() ) }>Save</div>
                </div>
                <div className="margin_auto margin_top_10px t5 color_light_gray pointer" onClick={ () => { history.push('/tabs/discover') } }>Skip for now</div>    
            </IonRow>
            


            
            <IonRow>
                {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
          </IonRow>
        </IonContent>
      </IonPage>
    )
}

AboutMeWizard.defaultProps = {};

export default AboutMeWizard;