// @ts-nocheck

import React, { useState, useRef, useEffect } from 'react';
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonBackButton,
  IonTitle, IonButtons, IonButton, IonSegment, IonSegmentButton, IonLabel, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonList, IonListHeader, IonItem, IonToggle, IonTextarea, IonNote, IonInput, IonText, IonModal,
  IonLoading
} from '@ionic/react';
import {
  close, personAdd, add, logoSnapchat, logoInstagram, logoRss, chevronBack
} from 'ionicons/icons';

import {
  courtli, getUser, uploadImages, deleteImage, changeUserProfileImage, courtliHealthCheckWithRedirect,
  getOnboardingNextStep,
  goBack
} from '../../apis/courtli/courtli';
import './MediaWizard.scss';
import { isError } from 'util';
// import * as jQuery from 'jquery';
// import '../../apis/dropzone/dropzone.css';

// import '../../apis/dropzone/dropzone.js';

type Props = {
  user: any,
  history: any
}

const MediaWizard: React.FC<Props> = ({ user, history }) => {

  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Uploading image...");

  const [doneButtonVisible, setDoneButtonVisible] = useState(false);

  const stackRef = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState<any>([
  ]);

  async function fetchImages() {
    let user = await getUser({ use_cache: false });

    console.log("MediaWizard fetchImages() user", user);

    /// IF THIS IS THE ONLY IMAGE THEN MAKE SURE TO CHANGE PROFILE IMAGE
    if (user.images.length > 0) {
      setDoneButtonVisible(true);
    }
    if (user.images.length === 1) {
      /// CHECK THAT THIS IMAGE ISNT ALREADY PROFILE
      if (user.profile_image_url !== user.images[0].url) {
        changeUserProfileImageAndReload(user.images[0].id);
        user.images[0].is_profile_image = true;
      }
    }

    setImages(user.images);
  }

  async function triggerImageUpload(event: any) {
    // console.log("triggerImageUpload() event", event);
    // Get a reference to the file that has just been added to the input
    const photos = event.target.files;
    const photo = photos[0];
    // Create a form data object using the FormData API
    let formData = new FormData();
    formData.append("token", courtli.token.token);
    // Add the file that was just added to the form data
    formData.append("photo", photo, photo.name);
    // POST formData to server using Fetch API
    // uploadImage(formData);

    toggleLoader("Uploading images...");

    await uploadImages(event, photos);
    fetchImages();
    toggleLoader("");

    window.gtag("event", "image_upload", {
      event_category: "actions",
      event_label: "image_upload"
    });

    /// IF THE USER HAS UPLOADED AN IMAGE, THEN SHOW DONE
    setDoneButtonVisible(true);
  }

  async function doneAndProceed() {
    /// INSTEAD OF GOING BACK, GO TO NEXT WIZARD
    let user_new = await getUser({ use_cache: false })
    console.log("MediaWizard doneAndProceed() user_new", user_new);
    let next_step = getOnboardingNextStep(user_new);
    history.push(next_step.wizard);
    window.location.reload();
  }
  function toggleFileInput() {
    document.getElementById("user_image_input")?.click();
    return true;
  }
  async function deleteImageAndReload(image_id: number) {
    toggleLoader("Deleting image...");
    await deleteImage(image_id);
    await fetchImages();

    toggleLoader("");
  }
  function toggleLoader(message: string) {
    if (message !== "") {
      setIsLoading(true);
      setLoadingMessage(message);
    } else {
      setIsLoading(false);
    }
  }

  async function changeUserProfileImageAndReload(image_id: number) {
    toggleLoader("Changing profile image...");
    await changeUserProfileImage(image_id);
    await fetchImages();
    toggleLoader("");
  }



  /// EXECUTION
  useEffect(() => {
    courtliHealthCheckWithRedirect(history);
    fetchImages();
  }, []);

  return (
    <>
      <IonPage className="wizard-page">

        <IonLoading
          isOpen={isLoading}
          message={loadingMessage}
          duration={5000}
        />

        {/*
        <IonHeader className="header-custom">
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => { goBack(history, { refresh: true }) }} >
                <IonIcon icon={chevronBack} />
              </IonButton>
            </IonButtons>
            <IonTitle>Edit Media</IonTitle>
            <IonButtons slot="end">
              <IonButton
                color="primary"
                hidden={(doneButtonVisible ? false : true)}
                onClick={() => { doneAndProceed() }}>
                Done
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        */}


        <IonHeader className="no-border">
          <IonToolbar className="user-profile-toolbar toolbar-no-border ">
            <IonButtons slot="start">
              <IonBackButton text={""} icon={chevronBack} onClick={() => { goBack(history, { refresh: true }) }} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>


        <IonContent className="profile-edit-page bg-light" fullscreen={true}>
          <div className="full-height">

            <IonRow>
              <div className="top-emoji">📷</div>
            </IonRow>
            <IonRow>
              <div className="top-title">
                Media
              </div>
            </IonRow>
            <IonRow>
              <div className="top-description">
                Add at least 1 picture. Double tap to choose your main
                profile picture. More pictures = more matches!
              </div>
            </IonRow>

            <div className="segment-view">
              <div className="photos-edit">


                <IonGrid className='photo-grid'>
                  <form action="/api/v1/document_editor/upload" className="dropzone upload_document_form display_none" method="post" encType="multipart/form-data" id="user_image_form">
                    <input name="review_id" type="hidden" id="document_editor_upload_review_id" />
                    <div className="fallback">
                      <input name="myFile" id="user_image_input" type="file" onChange={(event) => { triggerImageUpload(event) }} />
                      <input type="submit" value="Submit" />
                    </div>
                  </form>

                  <IonRow>
                    {images.map((image: any) => {
                      let image_url = "url(" + image.url + ")";
                      return <IonCol size="4" className="photo-item has-photo" key={image.url}>
                        <div className="photo-image background-img" style={{ backgroundImage: image_url }} />
                        {
                          !image.is_profile_image && <div className="photo-button-frame">
                            <div className="photo-button">
                              <div onClick={() => { changeUserProfileImageAndReload(image.id) }} className="pointer t4">⭐</div>
                              {/* <IonIcon icon={personAdd} onClick={ () => { changeUserProfileImage(image.id) } } className="pointer" /> */}
                            </div>
                            <div className="photo-button">
                              <div onClick={() => { deleteImageAndReload(image.id) }} className="pointer t4">❌</div>
                              {/* <IonIcon icon={close} onClick={ () => { deleteImageAndReload(image.id) } } className="pointer" /> */}
                            </div>
                          </div>
                        }

                      </IonCol>
                    })}

                    {
                      [0].map(i => (
                        <IonCol size="4" className="photo-item no-photo" key={i}>
                          <div className="photo-image background-img" />
                          <div className="photo-button-frame" onClick={() => { toggleFileInput() }}>
                            <div className="photo-button ">
                              <IonIcon icon={add} />
                            </div>
                          </div>
                        </IonCol>
                      ))
                    }
                  </IonRow>

                </IonGrid>

                {/*
                <IonList className="list-custom margin_top_20px">
                  <IonListHeader>
                    <IonLabel>PHOTO OPTIONS</IonLabel>
                  </IonListHeader>
                  <IonItem lines="none">
                    <IonLabel>Smart Photos</IonLabel>
                    <IonToggle color="primary" checked></IonToggle>
                  </IonItem>
                  <IonListHeader className="help-block">
                    <IonLabel color="medium">
                      Smart Photos continuously tests all your profile photos and picks the best one to show first.
                    </IonLabel>
                  </IonListHeader>
                </IonList>
                */}


                {doneButtonVisible &&
                  <IonRow class="wizard_action_row">
                    <div className="flex">
                      <div className="margin_auto  cbutton_red pointer" onClick={() => (doneAndProceed())}>CONTINUE</div>
                    </div>
                  </IonRow>
                }


              </div>
            </div>
          </div>


        </IonContent>
      </IonPage>
    </>
  );
};

MediaWizard.defaultProps = {};

export default MediaWizard;
