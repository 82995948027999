import React, { useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import './Share.scss';
import { motion } from "framer-motion";


/// COURTLI
import { getToken, getSharedUrl, setSharedId } from '../../apis/courtli/courtli';
import HeartsAnimation from '../../components/HeartsAnimation/HeartsAnimation';

type Props = {
  history: any,
  profile_image_url: any
}

const Share: React.FC<Props> = ({ history }) => {

  useEffect(() => {

    let hash = window.location.toString().split("/").pop() ?? "";
    getSharedUrl(hash).then(async (res) => {
      setSharedId(res);
      goToLogin();
    });

  }, []);

  const goToLogin = async () => {

    let tokenData = await getToken();
    let token = tokenData.token;

    if (token) {
      window.location.href = "login/token/" + token;
    } else {
      window.location.href = "";
    }

  };

  return (
    <IonPage>
      <IonContent forceOverscroll={false} className="login-page bg-gradient">

        <div className="vertical-layout safe-area-bottom">
          <div className="section-brand z101">
            <motion.div className="logo" animate={{ scale: [1, 1.5, 1] }} transition={{ duration: 1 }}>
              <img
                className="logo-icon"
                src="assets/img/courtli_logo_vertical.svg"
                alt=""
              />
            </motion.div>
          </div>

          <div className="section-buttons ion-padding">

          </div>
          <HeartsAnimation mode="landing_page" />

        </div>

      </IonContent>
    </IonPage>
  );
};

Share.defaultProps = {};

export default Share;
