import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { courtli, getVersion } from './apis/courtli/courtli';


const version = getVersion();
console.log("Launching " + version);



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

/// REMOVING THIS MAKES CACHING WORK???
/// MAKES SENSE - OTHERWISE YOU KILL THE WORKER BEFORE IT DOES ITS JOB OF REDIRECTING...?
// serviceWorkerRegistration.unregister();

/// AFTER 1 SEC REGISTER A FRESH WORKER
if (courtli.service_worker_enabled) {
  serviceWorkerRegistration.register();
} else {
  // serviceWorkerRegistration.unregister();
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
