

import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Helmet } from 'react-helmet';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Landing from './pages/Landing/Landing';
import Landing2 from './pages/Landing2/Landing2';
import Login from './pages/Login/Login';
import Tabs from './pages/Tabs/Tabs';
import Chat from './pages/Chat/Chat';
import Chats from './pages/Chats/Chats';
import Service from './pages/Service/Service';
import ServiceEdit from './pages/ServiceEdit/ServiceEdit';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import MentorProfilePage from './pages/MentorProfilePage/MentorProfilePage';

// SHARE
import Share from './pages/Share/Share';

import LoginFacebook from './pages/Login/Facebook/Facebook';
import LoginGoogle from './pages/Login/Google/Google';

// WIZARDS

import SetupWizard from './pages/Wizards/SetupWizard';
import RoleWizard from './pages/Wizards/RoleWizard';
import MediaWizard from './pages/Wizards/MediaWizard';
import GenderWizard from './pages/Wizards/GenderWizard';
import PhoneWizard from './pages/Wizards/PhoneWizard';
import LocationWizard from './pages/Wizards/LocationWizard';
import BirthdayWizard from './pages/Wizards/BirthdayWizard';
import HandWizard from './pages/Wizards/HandWizard';
import NameWizard from './pages/Wizards/NameWizard';
import PublishWizard from './pages/Wizards/PublishWizard';
import InterestsWizard from './pages/Wizards/InterestsWizard';
import AboutMeWizard from './pages/Wizards/AboutMeWizard';


import PrivacyPolicy from './pages/Docs/PrivacyPolicy';
import TermsAndConditions from './pages/Docs/TermsAndConditions';



/// UNDER CONSTRUCTION
import UnderConstruction from './pages/UnderConstruction/UnderConstruction';

/// TEST
import MatchedModal from './pages/MatchedModal/MatchedModal';
import OrderMadeModal from './pages/OrderMadeModal/OrderMadeModal';
import ReviewsWrite from './pages/ReviewsWrite/ReviewsWrite';
import Cards from './pages/Cards/Cards';


// import Matches from './pages/Matches/Matches';

/* Theme variables */
import './theme/variables.css';
import './theme/common.css';
import './theme/courtli.css';
import './theme/jp.css';

// custom CSS
import './sass/variables.override.scss';
import './sass/helper.scss';
import './sass/app.scss';
import './sass/dark.scss';
import ProfileEdit from './pages/ProfileEdit/ProfileEdit';

// import ReactGA from 'react-ga';

// /// GOOGLE ANALYTICS
// ReactGA.initialize('G-6X04H5X0P7');
// ReactGA.pageview(window.location.pathname + window.location.search);
// ReactGA.event({
//   category: 'User',
//   action: 'Opened app'
// });


setupIonicReact({
  mode: 'ios'
});





const App: React.FC = () => (
  <IonApp>
    <Helmet>
      {/* <!-- Google tag (gtag.js) --> */}
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-6X04H5X0P7"></script>
      <script src="/js/ga.js"></script>

      {/* CONTENT */}
      <link rel="apple-touch-icon" href="/assets/img/logo.jpeg" />
      {/* <link rel="apple-touch-icon" sizes="180x180" href="/assets/img/logo.jpeg" /> */}
      <link rel="icon" type="image/png" sizes="32x32" href="/assets/img/courtli_icon.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/assets/img/courtli_icon.png" />
      {/* <link rel="manifest" href="/site.webmanifest" /> */}
      <link rel="mask-icon" href="/assets/img/courtli_logo_vertical.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#5bbad5" />
      <meta name="theme-color" content="#5bbad5" />


      {/* CACHING = BAD */}
      <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
    </Helmet>
    <IonReactRouter>
      <IonRouterOutlet>

        <Route path="/2" component={Landing} exact />
        <Route path="/" component={Landing2} exact />
        {/* <Route path="/" render={() => <Redirect to="/landing" />} exact /> */}

        {/* LOGINS */}
        <Route path="/login/facebook" component={LoginFacebook} />
        <Route path="/login/google" component={LoginGoogle} />
        <Route path="/login" component={Login} exact />
        <Route path="/login/token/:token" component={Login} exact />



        <Route path="/tabs" component={Tabs} />
        <Route path="/profile" component={ProfileEdit} exact />
        <Route path="/service/:service_id" component={Service} exact />
        <Route path="/service/:service_id/edit" component={ServiceEdit} exact />
        <Route path="/profile/:user_id" component={ProfilePage} exact />
        <Route path="/mentor/:user_id" component={MentorProfilePage} exact />
        <Route path="/chats" component={Chats} exact />
        <Route path="/chat/:id" component={Chat} exact />
        {/* <Route path="/chat/0" component={UnderConstruction} exact/> */}


        {/* SHARE */}
        <Route path="/share" component={Share} />


        {/* WIZARDS */}
        <Route path="/wizards/setup" component={SetupWizard} />
        <Route path="/wizards/role" component={RoleWizard} />
        <Route path="/wizards/media" component={MediaWizard} />
        <Route path="/wizards/gender/:field_to_edit" component={GenderWizard} />
        <Route path="/wizards/phone" component={PhoneWizard} />
        <Route path="/wizards/location" component={LocationWizard} />
        <Route path="/wizards/birthday" component={BirthdayWizard} />
        <Route path="/wizards/hand" component={HandWizard} />
        <Route path="/wizards/name" component={NameWizard} />
        <Route path="/wizards/publish" component={PublishWizard} />
        <Route path="/wizards/interests" component={InterestsWizard} />
        <Route path="/wizards/about-me" component={AboutMeWizard} />

        {/* <Route path="/wizards/phone" component={PhoneWizard} /> */}

        {/* UnderConstruction */}
        <Route path="/under-construction" component={UnderConstruction} />


        {/* TEST */}
        <Route path="/matched-modal" component={MatchedModal} />
        <Route path="/cards" component={Cards} />
        <Route path="/order-made/:mentor_id" component={OrderMadeModal} />
        <Route path="/order/:order_id/review" component={ReviewsWrite} />

        {/* DOCS */}
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />

        <Route render={() => <Redirect to="/" />} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
