// @ts-nocheck

import React, { useState, useEffect } from "react";
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonButton,
  IonIcon,
  IonRow,
  IonCol,
  IonText,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonAvatar,
  IonPage,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonToggle,
  IonNote,
  IonTextarea,
  IonSearchbar
} from "@ionic/react";

import {
  accessibility,
  addOutline,
  briefcase,
  calendarOutline,
  chevronBack,
  chevronForward,
  chevronDown,
  chevronExpand,
  closeCircle,
  closeCircleOutline,
  create,
  fingerPrint,
  flashOff,
  flashOffOutline,
  heartDislikeCircleOutline,
  lockClosedSharp,
  maleFemaleOutline,
  notificationsOffOutline,
  rocket,
  sadOutline,
  school,
  skullOutline,
  heart,
  heartOutline,
  backspace,
  wallet,
  heartCircle,
  checkbox
} from "ionicons/icons";

import dayjs from "dayjs";
import "./UserActivity.scss";

//TODO CALC DISTANCE
import { getDistanceFromThisToUser } from '../../apis/courtli/courtli';


type Props = {
  user: any;
  onClose: () => void;
};

const UserActivity: React.FC<Props> = ({ user, onClose }) => {

  //ACTIVITIES
  var activityList = [
    { user: user, type: "user", activity: "You matched with Samantha!", icon: heartCircle, iconColor: "#FC566D", date: 1694113630259 },
    { user: user, type: "user", activity: "You liked Samantha!", icon: heartOutline, iconColor: "#FC566D", date: 1694113630259 },
    { user: user, type: "user", activity: "Samantha liked you!", icon: heart, iconColor: "#FC566D", date: 1694113630259 },
    { user: user, type: "mentee", activity: "Lilly accepted service offer!", subtitle: "Improve your Courtli account in 5 easy steps!", icon: checkbox, iconColor: "#0CBC8B", date: 1694113630259 },
    { user: user, type: "user", activity: "Denice liked you!", icon: heart, iconColor: "#FC566D", date: 1694113630259 },
    { user: user, type: "mentee", activity: "Nick declined service offer..", subtitle: "Will find 5 great matches per week", icon: backspace, iconColor: "#92949C", date: 1694113630259 },
    { user: user, type: "mentee", activity: "Offer sent to Lilly!", subtitle: "Improve your Courtli account in 5 easy steps!", icon: wallet, iconColor: "#FFDA58", date: 1694113630259 },
  ];

  const [activities, setReasonList] = React.useState([]);
  var activityItems = "";
  setReasonItems();

  function setReasonItems() {
    activityItems = activities.map((activity: any, i: number) =>
      <div className="item">
        <span className="item-date">{getDateFormatted(activity.date)}</span>
        <IonItem key={i} lines="none">
          <IonAvatar><img src={activity.user.profile_image_url} alt="" />
          </IonAvatar>
          <IonNote>{activity.activity}<br />
            {(activity.type == "user") && <span>{user.age}, {user.location_description}, {getDistanceString()}</span>}
            {(activity.type == "mentee") && <span>{activity.subtitle}</span>}
          </IonNote>

          <IonIcon icon={activity.icon} slot="end" style={{ color: activity.iconColor }} />
        </IonItem >
      </div>
    );
  }


  function getDateFormatted(time) {
    const formattedDate = dayjs(time).format("hh:mm MM-DD-YYYY");
    return formattedDate;
  }

  function getDistanceString() {
    return '0 miles away'
  }

  //INIT
  useEffect(() => {
    setReasonList(activityList);
  }, []);

  //TODO SEND REPORT TO BACKEND

  /// IF NO USER THEN FAIL
  if (!user) return null;

  console.log(user);

  return (

    <IonPage className="activity-page">

      <IonHeader className='no-border'>

        <IonToolbar className="toolbar-no-border ">
          <IonTitle>Activity</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon icon={chevronBack} />
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <div className="search-container">
          <IonSearchbar className="search-bar" placeholder={"Search by keywords..."} onIonInput={evt => changeSearchValue(evt)}></IonSearchbar>
          <IonIcon className='search-red-icon' icon={chevronForward} />
        </div>

        <div className="filrers-container">
          <div className='matches-filter'>
            <span>everything</span>
            <IonIcon icon={chevronDown} />
          </div>
          <div className='matches-filter'>
            <span>newest first</span>
            <IonIcon icon={chevronDown} />
          </div>
        </div>

      </IonHeader>

      <IonContent>
        <IonList className="list-custom">
          {activityItems}
        </IonList>
      </IonContent>

    </IonPage>

  );


};

UserActivity.defaultProps = {};

export default UserActivity;
