
import React from 'react';
import {
  IonRow, IonCol, IonButton, IonIcon
} from '@ionic/react';
import {
  star
} from 'ionicons/icons';
// import './ServiceCard.scss';


import { getMentorLevel } from '../../apis/courtli/courtli';

type Props = {
  user: any,
  history: any
}

const MentorAvatar: React.FC<Props> = ({ user, history }) => {

  // console.log("MentorAvatar user", user);

  console.log(user);

  function viewMentorProfile() {
    // console.log("MentorAvatar viewMentorProfile()");
    history.push("/mentor/" + user.id)
  }
  return (
    <table className="margin_top_10px" onClick={viewMentorProfile}>
      <tbody>
        <tr>
          <td>
            <div className="ctiny_avatar" >
              <img src={user.profile_image_url} />
            </div>
          </td>
          <td>
            <table className="margin_left_5px t4">
              <tbody>
                <tr>
                  <td>
                    <b>{user.first_name}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    {getMentorLevel(user)} mentor,
                  </td>
                </tr>
              </tbody>
            </table>

          </td>
        </tr>
      </tbody>
    </table>
  );
};

MentorAvatar.defaultProps = {

}

export default MentorAvatar;


