
import React, { Component, useEffect,useState } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
          IonItem,
          IonLabel,
          IonSpinner,
          IonBackButton

} from '@ionic/react';

import {courtli, courtliHealthCheckWithRedirect, initOnboarding, changeUserField} from '../../apis/courtli/courtli';

/// STYLESHEETS
import '../../theme/common.css';
import { caretBack } from 'ionicons/icons';
import './Wizards.scss';

// import './Facebook.css';


/// CONSTANTS


type Props = {
  history: any;
    user: any;
    onClose: () => void;
};

const HandWizard: React.FC<Props> = ({ history, user, onClose }) => {
  
    
    /// VARIABLES
    let facebook_initialized:boolean = false;
    let [nextStep, setNextStep] = useState<any>({
        title: "START NOW",
        wizard: "/tabs/profile"
    });
    /// FUNCTION DEFINITIONS
    

    /// EXECUTION
    useEffect(() => {
        courtliHealthCheckWithRedirect(history);
        initOnboarding(setNextStep);        
    }, [])

    function setHand(hand:string){
        if(hand === "right"){
            changeUserField("is_right_handed", 1);
        }else{
            changeUserField("is_right_handed", 0);
        }

        history.goBack();
    }
    return (
      <IonPage className="backround_almost_white">
        {/* <IonBackButton text="Previous" icon={caretBack} className="display_none"></IonBackButton> */}
        <IonContent className="ion-padding">
            <IonRow>
                <div className="megaemoji margin_auto margin_top_80px">🤲</div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px t1 color_dark_gray text_align_center">
                    Choose your handedness
                </div>
            </IonRow>
            <IonRow>
                <div className="margin_auto margin_top_40px text_align_center t4 color_light_gray">
                    This will affect things like button layout, but it might also help you find your perfect match!
                </div>
            </IonRow>
            <IonRow class="wizard_action_row">
                <div className="flex">
                    <div className="margin_auto  cbutton_red pointer" onClick={ () => ( setHand("left") ) }>Left</div>
                    <div className="margin_auto  cbutton_red pointer" onClick={ () => ( setHand("right") ) }>Right</div>
                </div>
            </IonRow>
            


            
            <IonRow>
                {/* <IonCol className="text-center margin_top_80px">
                <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
                <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
                <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
                </IonCol> */}
          </IonRow>
        </IonContent>
      </IonPage>
    )
}

HandWizard.defaultProps = {};

export default HandWizard;