
// @ts-nocheck

import React, { Component, useEffect } from 'react';
import { IonContent, IonText, IonRow, IonCol, IonHeader, IonPage, IonTitle, IonToolbar, IonButton, IonImg,
  IonItem,
  IonLabel,
  IonSpinner,
  IonButtons,
  IonBackButton

} from '@ionic/react';

import {
shield, checkmarkCircle, heartOutline, chevronBack
} from 'ionicons/icons';
import {courtli, loginWithFacebookToken, setToken, goToFirstPage} from '../../../apis/courtli/courtli';
///import { Plugins } from '@capacitor/core';
import {
  FacebookLogin,
  FacebookLoginResponse,
} from '@capacitor-community/facebook-login';
import { findByTestId } from '@testing-library/react';

/// STYLESHEETS
import '../../../theme/common.css';
import './Facebook.css';


/// CONSTANTS
const FACEBOOK_PERMISSIONS = [
    'email',
    'public_profile',
    'user_birthday',
    'user_photos',
    'user_gender'
  ];

const INITIAL_STATE = {
};


type Props = {
  history: any;
    user: any;
    onClose: () => void;
};

const Facebook: React.FC<Props> = ({ history, user, onClose }) => {
  
    
    /// VARIABLES
    let facebook_initialized:boolean = false;

    /// FUNCTION DEFINITIONS
    
    async function initFacebook(){
        await FacebookLogin.initialize({ appId: '863383781561723' });
        const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
        if (result.accessToken) {
          
            // Login successful.
            // console.log(`Facebook access token is ${result.accessToken.token}`);
            console.log("Facebook result", result);
            /// SEND TO BACKEND FOR VERIFICATION
            let login_result:any = await loginWithFacebookToken(result.accessToken.token);
            console.log("Facebook login_result", login_result);
            if(login_result.data.data){
              setToken({token: login_result.data.data.token});
            }
            /// FORWARD TO FIRST PAGE
            window.gtag("event", "login_facebook", {
                event_category: "access",
                event_label: "login"
            });
            goToFirstPage(history);
            
        }else{
          console.log("initFacebook() FAIL result", result);
          alert("Facebook login failed");
        }

        // const profile_result = await FacebookLogin.getProfile<{
        //     email: string;
        //     first_name: string;
        //     last_name: string;
        //     picture: string;
        //     short_name: string;
        // }>({ fields: ['first_name', 'last_name', 'email', 'picture', 'short_name'] });
        
        // console.log(`Facebook user's profile is `, profile_result);
    }

    /// EXECUTION
    useEffect(() => {
      if(facebook_initialized === false){
        facebook_initialized = true;
        initFacebook();
      }
    }, [])
    return (
      <IonPage>
        {/* <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>Connecting</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        
        <IonHeader className="header-custom">
          <IonToolbar className="toolbar-no-border">
            <IonButtons slot="start">
              <IonBackButton icon={chevronBack} text={""} defaultHref="/tabs/chats" />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonRow>
            <IonCol className="text-center">
              <IonImg className="title-img" src="assets/img/logo.jpeg" ></IonImg>
              <IonImg className="title-img" src="https://cdn.cdnlogo.com/logos/f/23/facebook.svg" ></IonImg>
              <IonText className="width_50p display_block margin_auto">Please make sure the Facebook popup is not blocked by your browser.</IonText>
              <IonSpinner name="bubbles" color="primary" className="transform_scale_3 margin_top_80px"></IonSpinner>
            </IonCol>
          </IonRow>
          {/* <IonRow>
            <IonCol className="text-center">
              <IonText className="title">
                Facebook Login in Capacitor app
              </IonText>
            </IonCol>
          </IonRow> */}
          {/* <IonRow>
            <IonCol className="text-center">
              <IonText className="text-center">
                By Enappd Team
              </IonText>
            </IonCol>
          </IonRow>
          <IonButton className="login-button" onClick={() => initFacebook()} expand="full" fill="solid" color="primary">
            Login with Facebook
          </IonButton> */}
        </IonContent>
      </IonPage>
    )
}

Facebook.defaultProps = {};

export default Facebook;