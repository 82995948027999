import React from 'react';
import {
  IonAvatar
} from '@ionic/react';
import {

} from 'ionicons/icons';
import './RippleLoader.scss';

type Props = {
  imageUrl: string,
  mode: string
}

const RippleLoader: React.FC<Props> = ({ imageUrl, mode }) => {
  // console.log("RippleLoader mode", mode);
  let color_class = "ripple_color_green";
  let ripple_divs = <>
      <div className="ripple-1 ripple_color_green"></div>
      <div className="ripple-2 ripple_color_green"></div>
  </>;
  if(mode === "MATCHES"){
     ripple_divs = <>
      <div className="ripple-1 ripple_color_red"></div>
      <div className="ripple-2 ripple_color_red"></div>
    </>;
  }

  /// OLD URL via Discover imageUrl
  let image_url = "https://courtli.com/assets/img/navigation/match.svg";


  return (
    <div className="ripple-loader">
      <img className="big_heart beating" src="https://courtli.com/assets/img/navigation/match.svg" />
    </div>

  );

  /// OLD LOADER
  // return (
  //   <div className="ripple-loader">
  //     <IonAvatar className="thumbnail-xl ripple-trigger">
  //       <img src={ image_url } alt="" />
  //     </IonAvatar>
  //     {ripple_divs}
  //   </div>

  // );
};

RippleLoader.defaultProps = {

}

export default RippleLoader;
