import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonContent,
  IonButtons,
  IonBackButton,
  IonTitle
} from "@ionic/react";
import {
  chevronBack
} from "ionicons/icons";

import { getFriendUser, getUserImages, bringProfileImageToTheTopAndTag } from '../../apis/courtli/courtli';
import MentorProfile from '../MentorProfile/MentorProfile';

type Props = {
  history: any;
};

const MentorProfilePage: React.FC<Props> = ({ history }) => {


  let params = useParams<{ user_id: string }>();
  let user_id = params.user_id;
  // console.log("MentorProfilePage user_id", user_id);

  let [user, setUser] = useState<any>(false);


  useEffect(() => {

    fetchData();

  }, []);

  async function fetchData() {
    // await getUser();
    let user_new = await getFriendUser({ friend_user_id: user_id });
    //console.log("MentorProfilePage fetchData() user_new", user_new);
    if (user_new) {
      let images = await getUserImages({ user_id: user_id });
      //console.log("MentorProfilePage fetchData() images", images);
      images.map((image: any) => {
        image.imageUrl = image.url
      });
      user_new.images = images;
      user_new.images = bringProfileImageToTheTopAndTag(user_new);
      setUser(user_new);
    } else {
      alert("Sorry, this user is not found.");
    }
  }

  /// IF NO USER THEN FAIL
  if (!user) return null;

  return (

    <IonPage>

      {user && <MentorProfile user={user} onClose={() => { history.goBack(); }} history={history} />}


    </IonPage>
  );
};

MentorProfilePage.defaultProps = {};

export default MentorProfilePage;
